/* Do not use variables from this file directly in components.
    Instead, use the variables from component_vars which are semantic and color-agnostic.
*/
:root {
  /* Green */
  --kog-colors-green-50: #F1FDF9;
  --kog-colors-green-100: #E4FAF3;
  --kog-colors-green-200: #B4F1DA;
  --kog-colors-green-300: #88E7C1;
  --kog-colors-green-400: #5EDCA4;
  --kog-colors-green-500: #00DD8D;
  --kog-colors-green-600: #00C880;
  --kog-colors-green-700: #019C64;
  --kog-colors-green-800: #01784D;
  --kog-colors-green-900: #00472D;

  /* Yellow */
  --kog-colors-yellow-50: #FFF9ED;
  --kog-colors-yellow-100: #F9F2E3;
  --kog-colors-yellow-200: #FDE8BC;
  --kog-colors-yellow-300: #FCD992;
  --kog-colors-yellow-400: #FAC765;
  --kog-colors-yellow-500: #FFBD29;
  --kog-colors-yellow-600: #F0AD18;
  --kog-colors-yellow-700: #D3960D;
  --kog-colors-yellow-800: #A07207;
  --kog-colors-yellow-900: #563C00;

  /* Orange */
  --kog-colors-orange-50: #FFF5F1;
  --kog-colors-orange-100: #FDEFEA;
  --kog-colors-orange-200: #FAD2C0;
  --kog-colors-orange-300: #F8B599;
  --kog-colors-orange-400: #F4946B;
  --kog-colors-orange-500: #FE783F;
  --kog-colors-orange-600: #E5642D;
  --kog-colors-orange-700: #C45526;
  --kog-colors-orange-800: #A2461F;
  --kog-colors-orange-900: #682B11;

  /* Pink */
  --kog-colors-pink-50: #FFF5F6;
  --kog-colors-pink-100: #FDEEF1;
  --kog-colors-pink-200: #F9CFD7;
  --kog-colors-pink-300: #F6B1BC;
  --kog-colors-pink-400: #F993A7;
  --kog-colors-pink-500: #FD6E89;
  --kog-colors-pink-600: #EC5673;
  --kog-colors-pink-700: #D43452;
  --kog-colors-pink-800: #B0203B;
  --kog-colors-pink-900: #790B1F;

  /* Aubergine */
  --kog-colors-aubergine-50: #F5EFF8;
  --kog-colors-aubergine-100: #EEEAF1;
  --kog-colors-aubergine-200: #DCD1E6;
  --kog-colors-aubergine-300: #C9B6D9;
  --kog-colors-aubergine-400: #B89ECE;
  --kog-colors-aubergine-500: #A47EC4;
  --kog-colors-aubergine-600: #8D56BC;
  --kog-colors-aubergine-700: #522676;
  --kog-colors-aubergine-800: #3A224B;
  --kog-colors-aubergine-900: #2A1636;

  /* Gray */
  --kog-colors-white: #FFF;
  --kog-colors-grey-0: #FFF;
  --kog-colors-grey-50: #FCFCF9;
  --kog-colors-grey-100: #F2F2F1;
  --kog-colors-grey-200: #EDEDEB;
  --kog-colors-grey-300: #E5E5E3;
  --kog-colors-grey-400: #D5D5D2;
  --kog-colors-grey-500: #C4C4C2;
  --kog-colors-grey-600: #929291;
  --kog-colors-grey-700: #6C6C6A;
  --kog-colors-grey-800: #50504E;
  --kog-colors-grey-900: #171715;

  /* Blue */
  --kog-colors-blue-50: #F0F4FF;
  --kog-colors-blue-100: #E0EAFF;
  --kog-colors-blue-200: #D0E0FF;
  --kog-colors-blue-300: #B3CAFC;
  --kog-colors-blue-400: #A0C0FF;
  --kog-colors-blue-500: #90B0FF;
  --kog-colors-blue-600: #7186F7;
  --kog-colors-blue-700: #5060E0;
  --kog-colors-blue-800: #3048C8;
  --kog-colors-blue-900: #192689;

  /* Light */
  --kog-colors-light-50: #FFF;
  --kog-colors-light-100: #FDFCFB;
  --kog-colors-light-200: #FBF9F6;
  --kog-colors-light-300: #F8F3EC;
  --kog-colors-light-400: #F5EEE3;
  --kog-colors-light-500: #F2E8D9;
  --kog-colors-light-600: #EEDFCC;
  --kog-colors-light-700: #EAD6BF;
  --kog-colors-light-800: #E5CCB1;
  --kog-colors-light-900: #E0C3A4;

  /* Alpha */
  --kog-colors-alpha-0: #17171500;
  --kog-colors-alpha-50: #1717150C;
  --kog-colors-alpha-100: #1717151C;
  --kog-colors-alpha-200: #1717152B;
  --kog-colors-alpha-300: #17171542;
  --kog-colors-alpha-400: #1717155B;
  --kog-colors-alpha-500: #1717157A;
  --kog-colors-alpha-600: #17171599;
  --kog-colors-alpha-700: #171715B2;
  --kog-colors-alpha-800: #171715CC;
  --kog-colors-alpha-900: #171715F2;
  --kogTransparent: var(--kog-colors-alpha-0);
  --kog-colors-alpha-hard-0: #FFF0;
  --kog-colors-alpha-hard-50: #FFFFFF0C;
  --kog-colors-alpha-hard-100: #FFFFFF1C;
  --kog-colors-alpha-hard-200: #FFFFFF2B;
  --kog-colors-alpha-hard-300: #FFFFFF42;
  --kog-colors-alpha-hard-400: #FFFFFF5B;
  --kog-colors-alpha-hard-500: #FFFFFF7A;
  --kog-colors-alpha-hard-600: #FFF9;
  --kog-colors-alpha-hard-700: #FFFFFFB2;
  --kog-colors-alpha-hard-800: #FFFC;
  --kog-colors-alpha-hard-900: #FFFFFFF2;

  /* Misc */
  --kogSkeleton: #eee;
}
