.content-editable .prompt-command {
  text-decoration-color: var(--kog-colors-yellow-500);
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-skip-ink: none;
  text-underline-offset: 10%;
}

.content-editable .prompt {
  font-weight: bold;
}
