.flexTable {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.flexTable__header,
.flexTable__row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flexTable__header {
  font-weight: bold;
}

.flexTable__header-alignCenter,
.flexTable__row-alignCenter {
  align-items: center;
}

.flexTable-striped > .flexTable__row:nth-child(2n) {
  background-color: var(--kogPlatformGray096);
}

.flexTable-hover > .flexTable__row:hover {
  background-color: var(--kogPlatformGray096);
}

.flexTableNested:last-child {
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.flexTableNested-hover .flexTable__row:hover {
  background-color: var(--kogPlatformGray096);
}

.flexTable__item {
  overflow-x: auto;
  flex-basis: 0;
  flex-grow: 1;

  width: 100%;

  overflow-wrap: break-word;
}

.flexTable__item-allowOverflow {
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
}

.flexTable__item-fixed-size-42 {
  flex: 0 0 42px;
}

.flexTable__item-fixed-size-50 {
  flex: 0 0 50px;
}

.flexTable__item-fixed-size-32 {
  flex: 0 0 32px;
}

.flexTable__item-fixed-size-60 {
  flex: 0 0 60px;
}

.flexTable__item-fixed-size-72 {
  flex: 0 0 72px;
}

.flexTable__item-fixed-size-84 {
  flex: 0 0 84px;
}

.flexTable__item-fixed-size-132 {
  flex: 0 0 132px;
}

.flexTable__item-size-1 {
  /* Default size, here for symmetry reasons */
}

.flexTable__item-size-2 {
  flex-grow: 2;
}

.flexTable__item-size-3 {
  flex-grow: 3;
}

.flexTable__item-size-4 {
  flex-grow: 4;
}

.flexTable__item-size-5 {
  flex-grow: 5;
}

.flexTable__item-size-6 {
  flex-grow: 6;
}

.flexTable__item-size-7 {
  flex-grow: 7;
}

.flexTable__item-size-8 {
  flex-grow: 8;
}

.flexTable__item-size-9 {
  flex-grow: 9;
}

.flexTable__item-size-10 {
  flex-grow: 10;
}

.flexTable__headerStyled {
  height: 50px;
  padding-right: var(--space-s);
  padding-left: var(--space-s);

  border-top: 2px solid var(--kogPlatformGray064);
  border-bottom: 2px solid var(--kogPlatformGray064);
}

.flexTable__rowStyled {
  height: 50px;
  padding-right: var(--space-s);
  padding-left: var(--space-s);
  border-bottom: 1px solid var(--kogPlatformGray084);
}
