@import './kog_bootstrap_override.css';

/*
 * LAP Main site structure elements
 */

body {
  position: relative;

  margin-top: 0;
  padding-top: 50px;
  padding-top: 0;

  font-size: var(--kog-font-size-default);
  color: var(--kog-text-default);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 50px;
  margin: initial;

  &::after, &::before {
    content: initial;
    display: initial;
  }
}


div#main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

div#main-container {
  flex: 1;
}

div#main #main-content {
  position: relative;
}

div#main div.base-messages {
  margin: 0;
}

#content {
  margin-top: 20px;
  padding-bottom: 10px;
}

.page-footer {
  padding: 30px 20px;

  font-size: var(--kog-font-size-default);
  color: var(--kogPlatformGray044);

  background: var(--kogPlatformGray093);
  border-top: 1px solid var(--kogPlatformGray084);
}

/*
 * Common structure templates
 */

.width-100 {
  clear: both;
  width: 100%;
}

.clear {
  position: relative;
  clear: both;
}

h1 {
  margin-bottom: 5px;
}

h1.underlined,
h2.underlined,
h3.underlined,
h4.underlined,
h5.underlined {
  border-bottom: 1px solid var(--kogPlatformGray051);
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
  font-weight: normal;
}

.small {
  font-size: 85%;
  color: var(--kogPlatformGray031);
}

.text-baseline {
  vertical-align: baseline;
}

/*
 * Common Forms
 */

form {
  margin: 0;
}

form .errorlist li {
  color: var(--kogPlatformRedDarken40);
}

textarea.admin_form_input {
  resize: none;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: default;
}

.modal {
  outline: none !important;
}

.modal-body,
.modal-header,
.modal-footer {
  position: relative;
}

input[type='text'] {
  width: 100%;
}

form input[type='text'] {
  margin-bottom: 0;
}

form input {
  width: 100%;
}

form input[type='checkbox'] {
  width: auto;
}

.modal form input[type='submit'],
.modal form input[type='button'] {
  width: auto;
}

form input.placeholder,
form textarea.placeholder {
  color: var(--kogPlatformGray064);
}

form ul.errorlist {
  margin-top: 10px;
  margin-bottom: 5px;
}

form tr td.required {
  font-weight: bold;
}

textarea {
  width: 100%;
}

.error {
  color: var(--kogPlatformRedDarken20);
}

.error.error-strong {
  font-weight: bold;
}

/*
 * Pagination
 */

.pagination a {
  border-left-width: 1px;
}

.pagination a.prev-link {
  border-left-width: 1px;
}

.pagination .current {
  width: 100%;
  text-align: center;
}

.pagination .current a {
  cursor: default;
}

/*
 * Rows
 */

.row.bottom-border {
  border-bottom: 1px solid var(--kogPlatformGray093);
}

/*
 * Labels
 */

label.non-required {
  font-weight: normal;
}

/*
 * Spacing
 */

.pull-right.pull-space {
  margin-left: 0.3em;
}

.pull-left.pull-space {
  margin-right: 0.3em;
}

/*
 * Tight panel
 */

.panel .panel-heading.panel-heading-tight h1,
.panel .panel-heading.panel-heading-tight h2,
.panel .panel-heading.panel-heading-tight h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.handle {
  cursor: pointer;
}

/*
 * Main menu bread crumbs
 * (will be a component once the old pages and menus dies,
 * if we still want this by then)
 */

.navbarBreadcrumb__item {
  display: block;

  padding-top: 15px;
  padding-bottom: 15px;

  font-size: var(--kog-font-size-default);
  line-height: 20px;
  color: var(--kogPlatformGray090);
}

.navbarBreadcrumb__item + .navbarBreadcrumb__item::before {
  content: '»';

  display: inline-block;

  padding: 0 5px;

  line-height: 20px;
  color: var(--kogPlatformGray090);
}

.navbarBreadcrumb__link {
  display: inline-block;
  color: var(--kogPlatformGray090);
  text-decoration: none;
}

.navbarBreadcrumb__link:hover {
  color: var(--kogPlatformWhite);
}

.navbarBreadcrumb__link:focus {
  color: var(--kogPlatformWhite);
}

@media (min-width: 768px) {
  .navbarBreadcrumb {
    float: left;
  }

  .navbarBreadcrumb__item {
    display: inline-block;
  }
}

/*
 * components.css
 * Common components, boxes, lists etc used site-wide
 */

/*
 * Common boxes
 */

.title-box {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--kogPlatformGray064);
}

.box-regular {
  margin: 0 0 20px 0;
  padding: 15px;
}

.box-rounded {
  padding: 15px;
  border-radius: 5px;
}

/*
 * Images
 */

img.block-centered {
  margin: 0 auto;
}

/*
 * Common for Tables
 */

table.table {
  margin: 15px 0;
}

figure > table.table {
  margin: 0 0;
}

table.table-content-list td,
table.table-content-list th {
  word-wrap: break-word;
  white-space: normal;
}

table tr.borders td {
  border-top: 1px solid var(--kogPlatformGray051);
}

table tr td.no-borders {
  border-top: none;
}

td.table_width_5,
th.table_width_5 {
  width: 5%;
}

td.table_width_15,
th.table_width_15 {
  width: 15%;
}

td.table_width_20,
th.table_width_20 {
  width: 20%;
}

td.table_width_35,
th.table_width_35 {
  width: 35%;
}

table.table tr.info th {
  background-color: var(--kogPlatformBlueLighten20);
}

.screenreader-only {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

/*
:focus-visible very slightly differs from :focus
so that css postprocessors don't merge the two rules into one.
Safari doesn't support focus:active and when both rules
are merged into one then :focus is broken.
*/

.screenreader-only:focus {
  position: absolute !important;
  outline: none !important;
}

.screenreader-only:focus-visible {
  position: absolute !important;
  border: none !important;
  outline: none !important;
}
