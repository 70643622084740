/* Legacy 5 pt spacing classes */

.margin-right-fine-2 {
  margin-right: 10px;
}

.margin-right-1 {
  margin-right: 20px;
}

.margin-left-fine-1 {
  margin-left: 5px;
}

.margin-left-fine-2 {
  margin-left: 10px;
}

.margin-left-1 {
  margin-left: 20px;
}

.margin-top-fine-1 {
  margin-top: 5px;
}

.margin-top-fine-2 {
  margin-top: 10px;
}

.margin-top-1 {
  margin-top: 20px;
}

.margin-bottom-fine-1 {
  margin-bottom: 5px;
}

.margin-bottom-fine-2 {
  margin-bottom: 10px;
}

.margin-bottom-1 {
  margin-bottom: 20px;
}

.margin-bottom-4 {
  margin-bottom: 80px;
}

.padd-fine-1 {
  padding: 5px;
}

.padd-fine-2 {
  padding: 10px;
}

.padd-1 {
  padding: 20px;
}

.padd-right-fine-1 {
  padding-right: 5px;
}

.padd-right-fine-2 {
  padding-right: 10px;
}

.padd-right-1 {
  padding-right: 20px;
}

.padd-left-fine-1 {
  padding-left: 5px;
}

.padd-left-fine-2 {
  padding-left: 10px;
}

.padd-left-1 {
  padding-left: 20px;
}

.padd-top-1 {
  padding-top: 20px;
}

.padd-bottom-fine-2 {
  padding-bottom: 10px;
}

.padd-bottom-1 {
  padding-bottom: 20px;
}

/* stylelint-disable declaration-property-value-whitelist */

:root {
  /* Legacy Design System constants */
  --header-height: 50px; /* Navbar default height */
  --footer-height: 80px; /* Footer default height */
}

/* stylelint-enable declaration-property-value-whitelist */
