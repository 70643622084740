.KogFormInput {
  display: block;

  width: 100%;
  min-height: 40px; /* Also used for textarea */
  padding: 8px 16px;

  font-size: 14px;

  border: solid 1px var(--kog-input-outline-border-color);
  border-radius: 4px;
}

.KogFormInput:focus,
.KogFormInput:focus-visible {
  border-color: var(--kog-input-outline-focus-border-color);
}

.KogFormInput:disabled {
  background-color: var(--kog-input-outline-disabled-background-color);
}

.KogFormInput:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-input-outline-hover-border-color);
}

.KogFormInput::placeholder {
  color: var(--kog-input-placeholder-color);
}

.KogFormInput--error {
  border-color: var(--kog-input-error-text-color);
}

.KogFormInput-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: var(--kogPlatformGray018);
}

.KogFormInput-helper {
  font-size: 12px;
  line-height: 20px;
  color: var(--kog-input-helper-text-color);
}

.KogFormInput-helper--error {
  color: var(--kog-input-error-text-color);
}

/* Old styles */

.KogFormInput--small {
  min-height: 32px;
  padding: 4px 8px;
  font-size: var(--kog-font-size-small);
}

.KogFormInput--thinBorder {
  border: 1px solid var(--kog-input-outline-border-color);
}

.KogFormInput--rightBorderRadius {
  border-radius: 0 4px 4px 0;
}
