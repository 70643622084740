/**
 * Container widths
 */

.container-wideWidth {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.container-mediumWidth {
  max-width: 750px;
  margin: 0 auto;
}

/**
 * Bootstrap container replacements
*/

.pageContainer {
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .pageContainer {
    max-width: 1170px;
  }
}

/**
 * Positioning
 */

.positionRelative {
  position: relative;
}

/**
 * Flex containers
 */

.flexContainer {
  display: flex;
}

.inlineflexContainer {
  display: inline-flex;
}

.flexContainer-center {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.flexContainer-alignEnd {
  align-items: flex-end;
}

.flexContainer-alignStart {
  align-items: flex-start;
}

.flexContainer-alignBaseline {
  align-items: baseline;
}

.flexContainer-alignCenter {
  align-items: center;
}

.flexContainer-alignStretch {
  align-items: stretch;
}

.flexContainer-column {
  flex-direction: column;
}

.flexContainer-row {
  flex-direction: row;
}

.flexContainer-rowReverse {
  flex-direction: row-reverse;
}

.flexContainer-wrap {
  flex-wrap: wrap;
}

.flexContainer-nowrap {
  flex-wrap: nowrap;
}

.flexContainer-spaceBetween {
  justify-content: space-between;
}

.flexContainer-spaceAround {
  justify-content: space-around;
}

.flexContainer-flexStart {
  justify-content: flex-start;
}

.flexContainer-flexEnd {
  justify-content: flex-end;
}

.flexContainer-justifyCenter {
  justify-content: center;
}

.flexChild-canGrow {
  flex-grow: 1;
}

.flexChild-noGrow {
  flex-grow: 0;
}

.flexChild-canShrink {
  flex-shrink: 1;
}

.flexChild-noShrink {
  flex-shrink: 0;
}

.flexChild-size-1 {
  flex: 1 0 0;
}

.flexChild-size-2 {
  flex: 2 0 0;
}

.flexChild-flexStart {
  align-self: flex-start;
}

.flexChild-flexEnd {
  align-self: flex-end;
}

.flexChild-flexStretch {
  align-self: stretch;
}

.flexChild-break {
  flex-basis: 100%;
  height: 0;
}

.flexChild-center {
  align-self: center;
}

.containContent {
  overflow: hidden;
}
