/* stylelint-disable plugin/selector-bem-pattern */

/*
:focus-visible very slightly differs from :focus
so that css postprocessors don't merge the two rules into one.
Safari doesn't support focus:active and when both rules
are merged into one then :focus is broken.
 */

*:focus-visible {
  outline: var(--kog-focus-outline) solid 4px;
  outline-offset: 4px;
}

*:focus {
  outline: var(--kog-focus-outline-safari) solid 4px;
  outline-offset: 4px;
}

*:focus:not(:focus-visible) {
  outline: none;
}
