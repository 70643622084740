.shadow-xs-010 {
  box-shadow: 0 2px 4px 0 var(--kogShadow010);
}

.shadow-s-010 {
  box-shadow: 0 4px 8px -2px var(--kogShadow010);
}

.shadow-m-010 {
  box-shadow: 0 8px 16px -4px var(--kogShadow010);
}

.shadow-l-010 {
  box-shadow: 0 12px 24px -6px var(--kogShadow010);
}

.shadow-xl-010 {
  box-shadow: 0 16px 32px -8px var(--kogShadow010);
}

.shadow-xs-020 {
  box-shadow: 0 2px 4px 0 var(--kogShadow020);
}

.shadow-s-020 {
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}

.shadow-m-020 {
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.shadow-l-020 {
  box-shadow: 0 12px 24px -6px var(--kogShadow020);
}

.shadow-xl-020 {
  box-shadow: 0 16px 32px -8px var(--kogShadow020);
}

.shadow-xs-030 {
  box-shadow: 0 2px 4px 0 var(--kogShadow030);
}

.shadow-s-030 {
  box-shadow: 0 4px 8px -2px var(--kogShadow030);
}

.shadow-m-030 {
  box-shadow: 0 8px 16px -4px var(--kogShadow030);
}

.shadow-l-030 {
  box-shadow: 0 12px 24px -6px var(--kogShadow030);
}

.shadow-xl-030 {
  box-shadow: 0 16px 32px -8px var(--kogShadow030);
}

.shadow-xs-080 {
  box-shadow: 0 2px 4px 0 var(--kogShadow080);
}

.shadow-s-080 {
  box-shadow: 0 4px 8px -2px var(--kogShadow080);
}

.shadow-m-080 {
  box-shadow: 0 8px 16px -4px var(--kogShadow080);
}

.shadow-l-080 {
  box-shadow: 0 12px 24px -6px var(--kogShadow080);
}

.shadow-xl-080 {
  box-shadow: 0 16px 32px -8px var(--kogShadow080);
}
