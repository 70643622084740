.font-kog-small {
  font-size: var(--kog-font-size-small);
}

.font-small {
  font-size: 0.8em;
}

.font-size-content {
  font-size: var(--kog-font-size-content);
}

.font-meta-serif-pro {
  font-family: var(--kog-meta-serif-pro);
  font-feature-settings: 'lnum';
}

.font-satoshi {
  font-family: var(--kog-satoshi);
}

.font-content-main {
  font-family: var(--kog-content-main);
  font-feature-settings: 'lnum';
}

/* Design System font sizing classes */

.heading-xl {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

.heading-l {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.heading-m {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.heading-s {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.heading-xs {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.heading-xxs {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.text-regular {
  font-size: 14px;
  line-height: 24px;
}

.text-small {
  font-size: 12px;
  line-height: 20px;
}

.text-overline {
  font-family: var(--kog-satoshi);
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-normal {
  font-weight: normal;
}
