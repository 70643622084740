.ck-flex-wrapper {
    display: flex;
    gap: var(--space-xs);
    justify-content: space-evenly;

    &.ck-flex-wrapper--row {
        flex-direction: row;
    }

    &.ck-flex-wrapper--col {
        flex-direction: column;

    }

    &.ck-flex-wrapper--hidden {
        display: none;
        visibility: hidden;
    }

    & > * {
        flex: 1;
    }
}
