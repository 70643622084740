@media print {
  .showsolution-toggle-link {
    display: none !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
