.content-editable .image img {
  display: block;

  min-width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.content-editable .image.image_resized img {
  height: auto;
}

.content-editable p.content-image-figure:not([style*='text-align']) img {
  height: auto;
  margin: 0;
}

.content-editable .image {
  clear: both;
  display: table;

  min-width: 50px;
  margin: 0.9em auto;

  text-align: center;
}

.content-editable .image-style-side {
  float: right;
  max-width: 50%;
  margin-left: 1.5em;
}
