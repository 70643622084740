.content-editable .CKEditorKognityGlossaryLink {
  display: inline-block;
  color: var(--kogPlatformBlueBase);
  border-bottom: 2px solid var(--kogPlatformBlueBase);
}

.content-editable .CKEditorKognityGlossaryLink p {
  margin: 0;
  padding: 0;
}
