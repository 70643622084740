.background-none {
  background: none;
}

.kogPlatformWhite-bg {
  background-color: var(--kogPlatformWhite);
}

.kogPlatformGray098-bg {
  background-color: var(--kogPlatformGray098);
}

.kogPlatformGray096-bg {
  background-color: var(--kogPlatformGray096);
}

.kogPlatformGray094-bg {
  background-color: var(--kogPlatformGray094);
}

.kogPlatformGray093-bg {
  background-color: var(--kogPlatformGray093);
}

.kogPlatformGray093 {
  color: var(--kogPlatformGray093);
}

.kogPlatformGray084 {
  color: var(--kogPlatformGray084);
}

.kogPlatformGray077 {
  color: var(--kogPlatformGray077);
}

.kogPlatformGray064 {
  color: var(--kogPlatformGray064);
}

.kogPlatformGray051 {
  color: var(--kogPlatformGray051);
}

.kogPlatformGray031 {
  color: var(--kogPlatformGray031);
}

.kogPlatformGray30 {
  color: var(--kog-colors-grey-300);
}

.kogPlatformGreenLighten20-bg {
  background-color: var(--kogPlatformGreenLighten20);
}

.kogPlatformGreenLighten20 {
  color: var(--kogPlatformGreenLighten20);
}

.kogPlatformGreenBase {
  color: var(--kogPlatformGreenBase);
}

.kogPlatformGreen50 {
  color: var(--kog-colors-green-500);
}

.kogPlatformGreenDarken10 {
  color: var(--kogPlatformGreenDarken10);
}

.kogPlatformGreenDarken20 {
  color: var(--kogPlatformGreenDarken20);
}

.kogPlatformYellowLighten20-bg {
  background: var(--kogPlatformYellowLighten20);
}

.kogPlatformYellowDarken10 {
  color: var(--kogPlatformYellowDarken10);
}

.kogPlatformRedBase {
  color: var(--kogPlatformRedBase);
}

.kogPlatformRedDarken10 {
  color: var(--kogPlatformRedDarken10);
}

.kogPlatformRedDarken20 {
  color: var(--kogPlatformRedDarken20);
}

.kogPlatformBlueBase {
  color: var(--kogPlatformBlueBase);
}

.kogPlatformBlueBase-bg {
  background: var(--kogPlatformBlueBase);
}

.kogPlatformBlueLighten10 {
  color: var(--kogPlatformBlueLighten10);
}

.kogPlatformBlueLighten20-bg {
  background: var(--kogPlatformBlueLighten20);
}

.kogPlatformBlueDarken10 {
  color: var(--kogPlatformBlueDarken10);
}

.kogPlatformBlueDarken20 {
  color: var(--kogPlatformBlueDarken20);
}

.kogContentPurple1 {
  color: var(--kog-content-purple-1);
}

.kogDanger-bg {
  background-color: var(--kog-button-danger-background-default);
}

.kogPrimary {
  color: var(--kog-text-brand);
}

.success-muted {
  color: var(--kog-text-success-muted);
}

.muted {
  color: var(--kog-text-muted);
}

.soft {
  color: var(--kog-text-soft);
}

.body-text {
  color: var(--kogPlatformGray018);
}

.body-text a {
  color: var(--kogPlatformBlueBase);
}

.body-text a:hover {
  color: var(--kogPlatformBlueDarken10);
}

.text-with-hover {
  color: var(--kogPlatformGray025);
}

.text-with-hover:hover {
  color: var(--kogPlatformGray018);
}

.text-white {
  color: var(--kogPlatformWhite);
}
