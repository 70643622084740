/* stylelint-disable scale-unlimited/declaration-strict-value */
.OpenEndedTask {
  color: #2f2f2c;
  border: 1px solid #d5d5d2;
}

.OpenEndedTask-heading {
  background-color: #ededeb;
}

.OpenEndedTask-body {
  background-color: #fff;
}
