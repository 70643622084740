/* stylelint-disable value-no-vendor-prefix */

.text-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-nowrap {
  white-space: nowrap;
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-5,
.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.line-clamp-6 {
  -webkit-line-clamp: 6;
}

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;

  -webkit-line-clamp: none;
}
