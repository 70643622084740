.ck-glossary-link-form {
    & .ck-button__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    & .ck-search__results{
        max-width: 40vh;
    }
}
