/*
Until we remove all of the headers in tables.
temporary fix to disable h-tag styling in tables
*/

.ContentBox-content table h1, .anybox-content table h1,
.ContentBox-content table h2, .anybox-content table h2,
.ContentBox-content table h3, .anybox-content table h3,
.ContentBox-content table h4, .anybox-content table h4,
.ContentBox-content table h5, .anybox-content table h5,
.ContentBox-content table h6, .anybox-content table h6 {
  display: inline  !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

.content-editable .ContentBox {
  position: relative;

  clear: both;

  margin-bottom: var(--space-xl);
  padding: var(--space-l);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: var(--space-l);

  border-radius: 24px;
}

.content-editable [contenteditable='true'] .ContentBox {
  margin-right: 0;
  margin-left: 0;
}

/* without it will be <p> <content-box> </p> in ckEditor 5 */
/* stylelint-disable-next-line selector-type-no-unknown */
.content-editable content-box {
  display: block;
}

.content-editable .ContentBox-icon {
  position: absolute;
  top: var(--space-l);
  left: var(--space-l);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}

.content-editable .ContentBox-icon i {
  font-size: 18px;
}

.content-editable .ContentBox-title span,
.content-editable .ContentBox-title :is(h2, h3, h4, h5, h6) {
  display: block;

  margin-top: 0 !important;
  margin-bottom: var(--space-s);
  margin-left: calc(var(--space-xxl) + var(--space-s));

  font-family: var(--kog-satoshi);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}

.content-editable .ContentBox-content {
  margin-bottom: 0;
}

.anybox-content, .ContentBox-content, .ck-content {
  & h1, h2, h3, h4, h5, h6 {
    &.heading-level-1 {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-s);
      font-size: 32px;
      line-height: 40px;
    }
    &.heading-level-2 {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-s);
      font-size: 24px;
      line-height: 32px;
    }
    &.heading-level-3 {
      margin-top: var(--space-l);
      margin-bottom: var(--space-s);
      font-size: 20px;
      line-height: 28px;
    }
    &.heading-level-4 {
      margin-top: var(--space-l);
      margin-bottom: var(--space-s);
      font-size: 16px;
      line-height: 24px;
    }
    &.heading-level-5 {
      margin-top: var(--space-l);
      margin-bottom: var(--space-s);
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.content-editable .ContentBox-content h2 {
  font-size: 32px !important;
  line-height: var(--space-xxl) !important;
}

.content-editable .ContentBox-content h2:first-child,
.content-editable .ContentBox-content h3:first-child,
.content-editable .ContentBox-content h4:first-child {
  margin-top: 26px;
}

.content-editable ol ol {
  list-style-type: lower-latin;
}
.content-editable ol ol ol  {
  list-style-type: lower-roman;
}
.content-editable ol ol ol ol {
  list-style-type: upper-latin;
}
.content-editable ol ol ol ol ol {
  list-style-type: upper-roman;
}

.content-editable .ContentBox-content p,
.content-editable .ContentBox-content ul,
.content-editable .ContentBox-content ol {
  margin-bottom: 1em;
}

.content-editable .ContentBox-content > *:last-child {
  margin-bottom: 0;
}

.content-editable .ContentBox-content,
.content-editable .ContentBox-content p {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  line-height: 26px;
  color: var(--kogPlatformGray018);
}

.content-editable .ContentBox-content ol li,
.content-editable .ContentBox-content ul li {
  margin-bottom: 8px !important;
}

.content-editable .ContentBox-content ol li *:last-child,
.content-editable .ContentBox-content ul li *:last-child {
  margin-bottom: 0 !important;
}

.content-editable .ContentBox-content blockquote {
  font-size: inherit;
  line-height: 22px;
}

.content-editable .ContentBox:hover .ContentBox-controls {
  display: block;
}

@media (--viewport-m) {
  .content-editable .ContentBox {
    margin-right: 0;
    margin-left: 0;
  }
}
