:root {
  --kogHighlightBorderWidth: 1px;
  --kogHighlightSelectorBorderWidth: 2px;
}

.KogHighlight-blue {
  background-color: color-mod(var(--kogPlatformBlueLighten10) alpha(30%));
  border-top: var(--kogHighlightBorderWidth) solid var(--kogPlatformBlueDarken20);
  border-bottom: var(--kogHighlightBorderWidth) solid var(--kogPlatformBlueDarken20);
}

.KogHighlight-blue--active {
  background-color: color-mod(var(--kogPlatformBlueLighten10) alpha(50%));
  border-top: var(--kogHighlightBorderWidth) solid var(--kogPlatformBlueDarken20);
  border-bottom: var(--kogHighlightBorderWidth) solid var(--kogPlatformBlueDarken20);
}

.KogHighlight-blue--selector {
  background-color: var(--kogPlatformBlueLighten10);
  border: var(--kogHighlightSelectorBorderWidth) solid var(--kogPlatformBlueDarken20);
}

.KogHighlight-green {
  background-color: color-mod(var(--kogPlatformGreenLighten10) alpha(50%));
  border-top: var(--kogHighlightBorderWidth) dashed var(--kogPlatformGreenDarken20);
  border-bottom: var(--kogHighlightBorderWidth) dashed var(--kogPlatformGreenDarken20);
}

.KogHighlight-green--active {
  background-color: color-mod(var(--kogPlatformGreenLighten10) alpha(70%));
  border-top: var(--kogHighlightBorderWidth) dashed var(--kogPlatformGreenDarken20);
  border-bottom: var(--kogHighlightBorderWidth) dashed var(--kogPlatformGreenDarken20);
}

.KogHighlight-green--selector {
  background-color: var(--kogPlatformGreenLighten10);
  border: var(--kogHighlightSelectorBorderWidth) dashed var(--kogPlatformGreenDarken20);
}

.KogHighlight-peach {
  background-color: color-mod(var(--kogPlatformRedBase) alpha(30%));
  border-top: var(--kogHighlightBorderWidth) solid color-mod(var(--kogPlatformRedBase) alpha(10%));
  border-bottom: var(--kogHighlightBorderWidth) solid
    color-mod(var(--kogPlatformRedBase) alpha(10%));
}

.KogHighlight-peach--active {
  background-color: color-mod(var(--kogPlatformRedBase) alpha(45%));
  border-top: var(--kogHighlightBorderWidth) solid color-mod(var(--kogPlatformRedBase) alpha(10%));
  border-bottom: var(--kogHighlightBorderWidth) solid
    color-mod(var(--kogPlatformRedBase) alpha(10%));
}

.KogHighlight-peach--selector {
  background-color: var(--kogPlatformRedBase);
  border: var(--kogHighlightSelectorBorderWidth) solid
    color-mod(var(--kogPlatformRedBase) alpha(10%));
}

.KogHighlight-yellow {
  background-color: color-mod(var(--kogPlatformYellowBase) alpha(50%));
  border-top: var(--kogHighlightBorderWidth) dotted var(--kogPlatformYellowDarken20);
  border-bottom: var(--kogHighlightBorderWidth) dotted var(--kogPlatformYellowDarken20);
}

.KogHighlight-yellow--active {
  background-color: color-mod(var(--kogPlatformYellowBase) alpha(70%));
  border-top: var(--kogHighlightBorderWidth) dotted var(--kogPlatformYellowDarken20);
  border-bottom: var(--kogHighlightBorderWidth) dotted var(--kogPlatformYellowDarken20);
}

.KogHighlight-yellow--selector {
  background-color: var(--kogPlatformYellowBase);
  border: var(--kogHighlightSelectorBorderWidth) dotted var(--kogPlatformYellowDarken20);
}
