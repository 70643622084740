/* stylelint-disable plugin/selector-bem-pattern */

/*  Extending styles from floating vue
    Usage: Add theme property
    Reference https://floating-vue.starpad.dev/guide/themes
*/

/**
 * Theme: kog-tooltip
 */
.v-popper--theme-kog-tooltip .v-popper__inner {
  max-width: 200px;
  padding: 5px 10px;

  font-size: 12px;
  color: var(--kogPlatformWhite);

  background: var(--kogPlatformGray000);
  border-radius: 3px;
}

.v-popper--theme-kog-tooltip .v-popper__arrow-outer {
  border-color: var(--kogPlatformGray000);
}

/**
 * Theme: kog-dropdown
 */
.v-popper.v-popper--theme-kog-dropdown {
  width: auto;
}
.v-popper--theme-kog-dropdown .v-popper__inner {
  overflow: initial;

  color: var(--kog-text-default);

  border: none;
  border-radius: var(--space-xs);
  box-shadow: 0 4px 4px var(--kog-dropdown-box-shadow-color);
}

.v-popper--theme-kog-dropdown .v-popper__arrow-outer {
  visibility: hidden;
}

.v-popper--theme-kog-dropdown .v-popper__arrow-inner {
  visibility: hidden;
}

/**
 * Theme: kog-popover
 */
.v-popper--theme-kog-popover .v-popper__inner {
  padding: var(--space-m);
  color: var(--kog-text-default);
  border-radius: var(--space-xxs);
  box-shadow: 0 2px 4px var(--kog-dropdown-box-shadow-color);
}

/**
  * Theme: kog-promotional-popover
 */
.v-popper--theme-kog-promotional-popover .v-popper__inner {
  padding: var(--space-m);
  background-color: var(--kog-background-brand-900);
  color: var(--kog-text-hard);
  border-radius: var(--space-s);
  box-shadow: 0 2px 4px var(--kog-dropdown-box-shadow-color);
}
.v-popper--theme-kog-promotional-popover-s .v-popper__inner {
  width: 168px;
}

.v-popper--theme-kog-promotional-popover-m .v-popper__inner {
  width: 212px;
}

.v-popper--theme-kog-promotional-popover-l .v-popper__inner {
  width: 340px;
}
