.SubjectClass-Logo--small {
  max-height: 30px;
  border-radius: 3px;
}

.SubjectClass-Logo--medium {
  max-height: 55px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 var(--kogShadow020);
}

.Modal-InnerContainer {
  margin-right: 65px;
  margin-left: 65px;
}

.Modal-InputHeader {
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}

.Modal-Note {
  color: var(--kogPlatformGray044);
}

@keyframes from-scaled-up-to-normal-scale {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.ClassDetailsModals-countAnimation {
  animation-name: from-scaled-up-to-normal-scale;
  /* When adjusting duration, also change timeout
  in animateStudentCount / animateTeacherCount */

  animation-duration: 0.2s;
  animation-iteration-count: 1;
}
