.KogSearchForm {
  display: flex;
}

.KogSearchForm-searchField {
  height: 40px;
  padding: 6px 12px;

  font-size: var(--kog-font-size-default);

  border: 1px solid var(--kogPlatformGray084);
  border-radius: 4px 0 0 4px;
}

.KogSearchForm-searchButton {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
