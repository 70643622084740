.positionFixed {
  position: fixed;
}

.positionFixed-bottomLeft {
  bottom: 0;
  left: 0;
}

.positionFixed-topRight {
  top: 0;
  right: 0;
}
