.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.display-none {
  display: none;
}

.inline {
  display: inline;
}
