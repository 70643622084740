.content-editable .showsolution {
  margin-bottom: 1em;
}

.content-editable span.showsolution-title,
.content-editable figcaption {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
}

.content-editable .showsolution a.js-showsolution-toggle.ck-editor__editable {
  border: 0;
}

.content-editable .showsolution a.js-showsolution-toggle p {
  min-width: var(--space-s);
  margin: 0;
}
