.content-editable .CKEditorKognityFileUpload {
  display: inline-flex;

  box-sizing: border-box;
  padding: var(--space-xs) var(--space-l) var(--space-xs) var(--space-s);

  line-height: 24px;
  color: var(--kogPlatformGray018);

  border: 1px solid var(--kogPlatformGray071);
  border-radius: 4px;
}

.content-editable .CKEditorKognityFileUpload:hover {
  text-decoration: none;
}

.content-editable .CKEditorKognityFileUpload-icon {
  margin-right: var(--space-s);
  font-size: var(--kog-font-size-default);
  line-height: 24px;
}

.content-editable .CKEditorKognityFileUpload-icon span {
  display: none;
}

.content-editable .CKEditorKognityFileUpload-text {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: 24px;
}
