@import '../../libs/bootstrap/css/bootstrap.css';

/* stylelint-disable declaration-property-value-whitelist, property-no-vendor-prefix, max-line-length, no-descending-specificity */

/*
 * Pure overrides of the bootstrap theme
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit; /* stylelint-disable-line */
  font-weight: bold;
}

a {
  color: var(--kog-link-color);
}

a:hover {
  color: var(--kog-link-hover-color);
}

a:focus {
  color: var(--kog-link-hover-color);
  outline: var(--kog-focus-outline-safari) solid 4px;
  outline-offset: 4px;
}

.js-focus-visible a.focus-visible {
  outline: solid 2px var(--kog-link-hover-color);
  outline-offset: 1px;
}

.navbar {
  background-color: var(--kog-background-brand-900);
  border-radius: 0;
  border-width: 0;
  color: var(--kog-text-brand-hard);
}

/* Bootstrap override */

body {
  font-family: var(--kog-satoshi);
}

@media (min-width: 768px) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 15px;
  }
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a {
  color: var(--kogPlatformGray090);
}

.navbar .brand {
  display: inline-block;
  height: 50px;
  padding: 13px;
}

.navbar .brand img {
  max-height: 100%;
}

.navbar-default ul.navbar-nav.nav li a:focus,
.navbar-default ul.navbar-nav.nav li a:hover {
  color: var(--kogPlatformWhite);
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: var(--kog-button-primary-background-active);
  color: var(--kog-button-primary-label-active);
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background: var(--kog-button-primary-background-hover);
  color: var(--kog-button-primary-label-hover);
}

.dropdown-menu {
  padding: 0;
}

.dropdown-menu > li > a {
  color: var(--kogPlatformGray018);
  padding: 10px 20px;
}

.dropdown-menu > li > a:hover {
  background-color: var(--kog-background-brand-900);
  color: var(--kog-text-brand-hard);
}

.dropdown-menu > li.notification > a:hover {
  background: var(--kog-list-item-hover-background);
}

.navbar-default ul.navbar-nav.nav li.notification a:hover {
  color: var(--kogPlatformGray000);
}

.navbar .dropdown img.user-account-profile-image {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  height: 18px;
  width: 18px;
}

.form-control:focus {
  border-color: color-mod(var(--kog-border-brand-900) alpha(80%));
  box-shadow: none;
}

/* Bootstrap override */

@media (max-width: 767px) {
  .navbar-collapse,
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    background: var(--kogPlatformGray018);
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: var(--kogPlatformGray018);
  }

  .navbar-collapse {
    margin: 0 3px 3px;
  }

  .navbar-default .nav.navbar-nav {
    margin-bottom: 0;
    margin-top: 0;
  }

  .navbar-default .nav.navbar-nav > li {
    border-bottom: 1px solid var(--kogPlatformWhite);
    border-top: 1px solid var(--kogPlatformGray064);
  }

  .navbar-default ul.navbar-nav.nav li a:focus,
  .navbar-default ul.navbar-nav.nav li a:hover,
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    background-color: var(--kog-button-primary-background-active);
    color: var(--kog-button-primary-label-active);
  }

  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    background: var(--kog-button-primary-background-hover);
    color: var(--kog-button-primary-label-hover);
  }

  .navbar-default .navbar-nav .open .dropdown-menu {
    margin: 0;
    padding: 0;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background: var(--kogPlatformGray093);
    background-color: var(--kogPlatformGray093);
  }

  .navbar-nav .open ul.dropdown-menu {
    background: var(--kogPlatformWhite);
  }

  .navbar-nav .open ul.dropdown-menu > li.subject-item {
    border-bottom: 1px solid var(--kogPlatformGray093);
  }
}

li.notification {
  padding: 5px;
}

/* Modal css */

@media screen and (min-width: 768px) {
  .modal-dialog {
    margin: 10px auto;
    width: 750px;
  }
}

.modal-dialog .modal-content {
  overflow: hidden;
}

/* Input group addon */

.input-group-addon {
  background-color: var(--kogPlatformGray090);
}

/* flat-picker selected day color */

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: var(--kog-background-brand-900) !important;
  border-color: var(--kog-border-brand-900) !important;
  color: var(--kog-text-brand-hard) !important;
}

.alert-info {
  background-color: var(--kog-alert-info-background);
  border-color: var(--kogTransparent);
  color: var(--kog-text-default);
}

/* stylelint-enable */
