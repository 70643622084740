/*
 * Base Styles
 */

.content-editable .anybox {
  position: relative;
  clear: both;
  margin-bottom: var(--space-xl);
  padding: var(--space-l) var(--space-xxl) var(--space-xxl) 80px;
}

.content-editable table .anybox,
.content-editable blockquote .anybox,
.content-editable [contenteditable='true'] .anybox {
  margin-right: 0;
  margin-left: 0;
}

.content-editable .genericbox {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: var(--space-l);

  border-radius: 24px;
}

.content-editable > .genericbox > .anybox-content {
  margin-bottom: 0;
}

.content-editable .anybox .anybox-title span,
.content-editable .anybox .anybox-title :is(h2, h3, h4, h5, h6) {
  margin-top: 0 !important;
  margin-bottom: var(--space-xs);

  font-family: var(--kog-satoshi);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}

.content-editable .anybox-content p,
.content-editable .anybox-content ul,
.content-editable .anybox-content ol {
  margin-bottom: 1em;
}

.content-editable .anybox-content > *:last-child {
  margin-bottom: 0;
}

.content-editable .anybox > .anybox-content,
.content-editable .anybox > .anybox-content ul,
.content-editable .anybox > .anybox-content ol,
.content-editable .anybox > .anybox-content p {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  line-height: 26px;
  color: var(--kogPlatformGray018);
}

.content-editable .anybox > .anybox-content ol li,
.content-editable .anybox > .anybox-content ul li {
  margin-bottom: 8px !important;
}

.content-editable .anybox > .anybox-content ol li *:last-child,
.content-editable .anybox > .anybox-content ul li *:last-child {
  margin-bottom: 0 !important;
}

.content-editable .anybox-content blockquote {
  font-size: inherit;
  line-height: 22px;
}

.content-editable .anybox:hover .anybox-controls {
  display: block;
}

/* Common icons */

.content-editable .anybox .icon_div {
  position: absolute;
  top: var(--space-l);
  left: var(--space-l);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}

.content-editable .anybox .icon_div span {
  position: relative;
  /* FA base style */
  display: inline-block;

  margin-left: 1px; /* alignment aid */

  font: var(--kog-content-fa-icon);
  font-size: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}

.content-editable .anybox .icon_div span::before {
  font-size: 18px;
}

.content-editable .anybox .icon_div i {
  margin-left: 2px;
}

/*
 * Boxes Styles
 */

/* Definition box */

.content-editable .definitionbox {
  background: var(--kog-content-yellow-dark-2);
}

.content-editable .definitionbox > .icon_div span::before {
  /* Icon for .fa-check */
  content: '\f00c';
}

/* Tutor (Exam tip) box */

.content-editable .consejodeexamenbox,
.content-editable .conseilexamenbox,
.content-editable .tutorbox {
  background: var(--kog-content-yellow-light-2);
}

.content-editable .consejodeexamenbox > .icon_div span::before,
.content-editable .conseilexamenbox > .icon_div span::before,
.content-editable .tutorbox > .icon_div span::before {
  /* Icon for .fa-exclamation-circle */
  content: '\f06a';
}

/* Alert box */

.content-editable .alertbox {
  background: var(--kog-content-yellow-light-2);
}

.content-editable .alertbox > .icon_div span::before {
  /* Icon for .fa-warning */
  content: '\f071';
}

/* Summary box */

.content-editable .learningoutcomesbox,
.content-editable .summarybox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .learningoutcomesbox > .icon_div span::before,
.content-editable .summarybox > .icon_div span::before {
  /* Icon for .fa-list-alt */
  content: '\f022';
}

/* Learning intentions box */

.content-editable .learningintentionsbox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .learningintentionsbox > .icon_div span::before {
  /* Icon for .fa-list-alt */
  content: '\f022';
}

/* Term definition box */

.content-editable .termdefinitionbox {
  background: var(--kog-content-green-light-10);
}

.content-editable .termdefinitionbox > .icon_div span::before {
  /* Icon for .fa-star */
  content: '\f005';
}

/* Nature of Science box */

.content-editable .natureofsciencebox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .natureofsciencebox > .icon_div span::before {
  /* Icon for .fa-leaf */
  content: '\f06c';
}
.content-editable .natureofsciencebox :is(h1, h2, h3, h4, h5, h6):has(> br:only-child)::before {
  content: 'Nature of Science';
}

/* Theory of knowledge box */

.content-editable .teoriadelconocimientobox,
.content-editable .theoriedelaconnaissancebox,
.content-editable .theoryofknowledge {
  background: var(--kog-content-purple-light-4);
}

.content-editable .teoriadelconocimientobox > .icon_div span::before,
.content-editable .theoriedelaconnaissancebox > .icon_div span::before,
.content-editable .theoryofknowledge > .icon_div span::before {
  /* Icon for .fa-puzzle-piece */
  content: '\f12e';
}

/* International-mindedness box */

.content-editable .mentalidadinternacionalbox,
.content-editable .internationalmindedness {
  background: var(--kog-content-blue-light-10);
}

.content-editable .mentalidadinternacionalbox > .icon_div span::before,
.content-editable .internationalmindedness > .icon_div span::before {
  /* Icon for .fa-globe */
  content: '\f0ac';
}

/* Case study box */

.content-editable .casestudy {
  background: var(--kogPlatformGray096);
}

.content-editable .casestudy > .icon_div span::before {
  /* Icon for .fa-suitcase */
  content: '\f0f2';
}

/* Claim box */

.content-editable .practicequestionbox,
.content-editable .claimbox {
  background: var(--kog-content-purple-light-4);
}

.content-editable .practicequestionbox > .icon_div span::before,
.content-editable .claimbox > .icon_div span::before {
  /* Icon for .fa-asterisk */
  content: '\f069';
}

/* Activity box */

.content-editable .actividadbox,
.content-editable .activitebox,
.content-editable .activitybox {
  overflow-x: auto;
  background: var(--kog-content-red-light-5);
}

.content-editable .actividadbox > .icon_div span::before,
.content-editable .activitebox > .icon_div span::before,
.content-editable .activitybox > .icon_div span::before {
  /* Icon for .fa-asterisk */
  content: '\f085';
}

/* Hint box */

.content-editable .hintbox {
  background: var(--kog-content-yellow-dark-1);
}

.content-editable .hintbox > .icon_div span::before {
  /* Icon for  circle */
  content: '\f111';
}

/* Making connections box */

.content-editable .makingconnectionsbox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .makingconnectionsbox > .icon_div span::before {
  /* Icon for link */
  content: '\f0c1';
}

/* Practical box */

.content-editable .practicalskillsbox,
.content-editable .practical {
  background: var(--kog-content-red-light-5);
}

.content-editable .practicalskillsbox > .icon_div span::before,
.content-editable .practical > .icon_div span::before {
  /* Icon for .fa-flask */
  content: '\f0c3';
}

/* Field work opportunity box */

.content-editable .fieldworkopportunitybox {
  background: var(--kogPlatformGray096);
}

.content-editable .fieldworkopportunitybox > .icon_div span::before {
  /* Icon for .fa-location-arrow */
  content: '\f124';
}

/* Map skills box */

.content-editable .mapskillsbox {
  background: var(--kog-content-yellow-dark-1);
}

.content-editable .mapskillsbox > .icon_div span::before {
  /* Icon for .fa-map-marked-alt */
  content: '\f5a0';
}

/* Study skills and Skills box */

.content-editable .scienceandengineeringpracticebox,
.content-editable .studyskills,
.content-editable .skills {
  background: var(--kog-content-green-light-11);
}

.content-editable .scienceandengineeringpracticebox > .icon_div span::before,
.content-editable .studyskills > .icon_div span::before,
.content-editable .skills > .icon_div span::before {
  /* Icon for .fa-thumb-tack */
  content: '\f08d';
}

/* Concept box */

.content-editable .conceptobox,
.content-editable .crosscuttingconceptbox,
.content-editable .frenchconceptbox,
.content-editable .conceptbox {
  background: var(--kog-content-purple-light-4);
}

.content-editable .conceptobox > .icon_div span::before,
.content-editable .crosscuttingconceptbox > .icon_div span::before,
.content-editable .frenchconceptbox > .icon_div span::before,
.content-editable .conceptbox > .icon_div span::before {
  /* Icon for .fa-key */
  content: '\f084';
}

/* Vocabulary and key terms boxes */

.content-editable .keytermsbox,
.content-editable .vocabulariobox,
.content-editable .vocabulairebox,
.content-editable .vocabularybox {
  background: var(--kog-content-green-light-10);
}

.content-editable .keytermsbox > .icon_div span::before,
.content-editable .vocabulariobox > .icon_div span::before,
.content-editable .vocabulairebox > .icon_div span::before,
.content-editable .vocabularybox > .icon_div span::before {
  /* Icon for .fa-sort-alpha-down */
  content: '\f15d';
}

/* Nota gramatical and grammar tips boxes */

.content-editable .notagramaticalbox,
.content-editable .conseilgrammairebox,
.content-editable .grammartipbox {
  background: var(--kog-content-green-light-11);
}

.content-editable .notagramaticalbox > .icon_div span::before,
.content-editable .conseilgrammairebox > .icon_div span::before,
.content-editable .grammartipbox > .icon_div span::before {
  /* Icon for .fa-spell-check */
  content: '\f891';
}

/* Extract box */

.content-editable .extractobox,
.content-editable .extraitbox,
.content-editable .extractbox {
  background: var(--kogPlatformGray096);
}

.content-editable .extractobox > .icon_div span::before,
.content-editable .extraitbox > .icon_div span::before,
.content-editable .extractbox > .icon_div span::before {
  /* Icon for .fa-search */
  content: '\f002';
}

/* Creativity box */

.content-editable .creatividadactividadyserviciobox,
.content-editable .creativiteactiviteservicebox,
.content-editable .casbox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .creatividadactividadyserviciobox > .icon_div span::before,
.content-editable .creativiteactiviteservicebox > .icon_div span::before,
.content-editable .casbox > .icon_div span::before {
  /* Icon for .fa-seedling */
  content: '\f4d8';
}

/* Your content box */

.content-editable .yourcontextbox {
  background: var(--kog-content-blue-light-9);
}

.content-editable .yourcontextbox > .icon_div span::before {
  /* Icon for .fa-seedling */
  content: '\f015';
}

/* Esprit international box */

.content-editable .espritinternationalbox {
  background: var(--kog-content-blue-light-10);
}

.content-editable .espritinternationalbox > .icon_div span::before {
  /* Icon for .fa-globe */
  content: '\f0ac';
}

/* Extended boxes */

.content-editable .ampliacionparaelnivelsuperiorbox .anybox,
.content-editable .hlextensionbox .anybox,
.content-editable .approfondissementbox .anybox,
.content-editable .extendedcontentbox .anybox {
  margin-right: 0;
  margin-left: 0;
}

.anybox-content .exercise-box-header,
.anybox-content .reflection-box-header {
  position: unset !important;
}

.content-editable .ampliacionparaelnivelsuperiorbox > .icon_div,
.content-editable .hlextensionbox > .icon_div,
.content-editable .approfondissementbox > .icon_div,
.content-editable .extendedcontentbox > .icon_div {
  display: none !important;
}

.content-editable .ampliacionparaelnivelsuperiorbox,
.content-editable .hlextensionbox,
.content-editable .approfondissementbox,
.content-editable .extendedcontentbox {
  margin-left: var(--space-m);
  padding: var(--space-xxs) var(--space-xxl) var(--space-xxs) var(--space-xl);
}

.content-editable [contenteditable='true'] .ampliacionparaelnivelsuperiorbox,
.content-editable [contenteditable='true'] .hlextensionbox,
.content-editable [contenteditable='true'] .extendedcontentbox {
  margin-left: var(--space-xxl);
}

.content-editable .ampliacionparaelnivelsuperiorbox::before,
.content-editable .hlextensionbox::before,
.content-editable .approfondissementbox::before,
.content-editable .extendedcontentbox::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 4px;
  height: 100%;

  background-color: var(--kogPlatformRedLighten10);
  border-radius: 20px;
}

.content-editable .ampliacionparaelnivelsuperiorbox > .anybox-content,
.content-editable .ampliacionparaelnivelsuperiorbox > .anybox-content > p,
.content-editable .hlextensionbox > .anybox-content,
.content-editable .hlextensionbox > .anybox-content > p,
.content-editable .approfondissementbox > .anybox-content,
.content-editable .approfondissementbox > .anybox-content > p,
.content-editable .extendedcontentbox > .anybox-content,
.content-editable .extendedcontentbox > .anybox-content > p {
  font-family: var(--kog-content-main);
  font-size: 19px;
  font-feature-settings: 'lnum';
  font-weight: 400;
  line-height: var(--space-xl);
}

.content-editable .ampliacionparaelnivelsuperiorbox > .anybox-content > p,
.content-editable .hlextensionbox > .anybox-content > p,
.content-editable .approfondissementbox > .anybox-content > p,
.content-editable .extendedcontentbox > .anybox-content > p {
  line-height: var(--space-xl);
}

/*
 * Media Queries
 */

@media (--viewport-m) {
  .content-editable .anybox {
    margin-right: 0;
    margin-left: 0;
  }
}
