.hide-fade {
  overflow: hidden;
  transition: opacity 0.25s;
}

.hide-fade.ng-hide {
  opacity: 0;
}

.hide-fade.ng-hide-add,
.hide-fade.ng-hide-remove {
  display: block !important;
}

.hide-fade.ng-hide-add-active {
  opacity: 0;
}

.hide-fade.ng-hide-remove-active {
  opacity: 1;
}

.slideFade__container {
  position: relative;
  overflow: hidden;
}

.slideFade-right.ng-hide-add {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  animation: animToRight 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.slideFade-right.ng-hide-remove {
  animation: animFromLeft 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.slideFade-left.ng-hide-add {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  animation: animToLeft 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.slideFade-left.ng-hide-remove {
  animation: animFromRight 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.backdrop-fadeIn {
  animation: backdropFadeIn 0.15s forwards ease-out;
}

.backdrop-fadeOut {
  animation: backdropFadeOut 0.15s forwards ease-out;
}

.slideFadeIn-bottom {
  animation: fadeInFromBottom 0.15s forwards ease-out;
}

.slideFadeOut-top {
  animation: fadeOutFromTop 0.15s forwards ease-out;
}

.no-animate {
  /* This CSS can be used when ngAnimate causes delay in show/hide */

  transition: none !important;
}

.wiggle02 {
  animation: wiggle02 0.2s linear 3;
}

.wiggle06 {
  animation: wiggle06 0.2s linear 3;
}

@keyframes fadeInFromBottom {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeOutFromTop {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}

@keyframes backdropFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes backdropFadeOut {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
}

@keyframes animToRight {
  to {
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
  }
}

@keyframes animFromRight {
  from {
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
  }
}

@keyframes animToLeft {
  to {
    transform: translate3d(200px, 0, 0);
    opacity: 0;
  }
}

@keyframes animFromLeft {
  from {
    transform: translate3d(200px, 0, 0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes wiggle02 {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wiggle06 {
  25% {
    transform: rotate(-6deg);
  }
  75% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
