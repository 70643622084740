/* Style for lists used within CKEditor for consistent look in content */

.content-editable ol {
    list-style-type: decimal;
}
.content-editable ol ol {
    list-style-type: lower-latin;
}
.content-editable ol ol ol {
    list-style-type: lower-roman;
}
.content-editable ol ol ol ol {
    list-style-type: upper-latin;
}
.content-editable ol ol ol ol ol {
    list-style-type: upper-roman;
}
.content-editable ul {
    list-style-type: disc;
}
.content-editable ul ul {
    list-style-type: circle;
}
.content-editable ul ul ul {
    list-style-type: square;
}
.content-editable ul ul ul ul {
    list-style-type: square;
}
