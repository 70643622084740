/* stylelint-disable plugin/selector-bem-pattern */

.KogButtonLegacy {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-block;

  box-sizing: border-box;
  min-width: 112px;
  height: 40px;
  padding: 8px 24px;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  line-height: 22px;
  color: var(--kog-button-default-label-default);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  background: var(--kogPlatformWhite);
  border-color: var(--kog-button-default-border-default);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  transition:
    background 0.1s ease,
    border-color 0.1s ease;
}

.KogButtonLegacy:focus {
  color: var(--kog-button-default-label-focus); /* for a tag */
}

.KogButtonLegacy:disabled,
.KogButtonLegacy[aria-disabled='true'] {
  pointer-events: none;
  cursor: not-allowed;

  color: var(--kog-button-default-label-disabled);

  background: var(--kogPlatformWhite);
  border-color: var(--kog-button-default-border-disabled);
}

.KogButtonLegacy:hover:not(:disabled),
.KogButtonLegacy:hover:not([aria-disabled='true']) {
  color: var(--kog-button-default-label-hover); /* for a tag */
  background: var(--kog-button-default-background-hover);
}

.KogButtonLegacy:active:not([disabled]),
.KogButtonLegacy:active:not([aria-disabled='true']) {
  color: var(--kog-button-default-label-active);
  background: var(--kog-button-default-background-active);
  border-color: var(--kog-button-default-label-active);
}

a.KogButtonLegacy {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

a.KogButtonLegacy:focus {
  text-decoration: none;
}

a.KogButtonLegacy:hover {
  text-decoration: none;
}

.KogButtonLegacy--iconRight > i {
  padding-left: var(--space-s);
}

.KogButtonLegacy--iconLeft > i {
  padding-right: var(--space-s);
}

a.KogButtonLegacy:not(.KogButtonLegacy--iconLeft):not(.KogButtonLegacy--iconRight) > i {
  margin: 0 4px;
}

.KogButtonLegacy--primary {
  color: var(--kog-button-primary-label-default);
  background: var(--kog-button-primary-background-default);
  border-color: var(--kog-button-primary-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButtonLegacy--primary:focus {
  color: var(--kog-button-primary-label-default); /* for a tag */
}

.KogButtonLegacy--primary:disabled,
.KogButtonLegacy--primary[aria-disabled='true'] {
  color: var(--kog-button-primary-label-disabled);
  background: var(--kog-button-primary-background-disabled);
}

.KogButtonLegacy--primary:hover:not(:disabled),
.KogButtonLegacy--primary:hover:not([aria-disabled='true']) {
  color: var(--kog-button-primary-label-hover); /* for a tag */
  background: var(--kog-button-primary-background-hover);
  border-color: var(--kog-button-primary-border-hover);
}

.KogButtonLegacy--primary:active:not(:disabled),
.KogButtonLegacy--primary:active:not([aria-disabled='true']) {
  color: var(--kog-button-primary-label-active);
  background: var(--kog-button-primary-background-active);
  border-color: var(--kog-button-primary-border-active);
}

.KogButtonLegacy--primary.glowing {
  animation-name: primaryButtonPulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.KogButtonLegacy--danger {
  color: var(--kog-button-danger-label-default);
  background: var(--kog-button-danger-background-default);
  border-color: var(--kog-button-danger-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButtonLegacy--danger:focus {
  color: var(--kog-button-danger-label-default); /* for a tag */
}

.KogButtonLegacy--danger:disabled,
.KogButtonLegacy--danger[aria-disabled='true'] {
  color: var(--kog-button-danger-label-disabled);
  background: var(--kog-button-danger-background-disabled);
}

.KogButtonLegacy--danger:hover:not(:disabled),
.KogButtonLegacy--danger:hover:not([aria-disabled='true']) {
  color: var(--kog-button-danger-label-hover); /* for a tag */
  background: var(--kog-button-danger-background-hover);
  border-color: var(--kog-button-danger-border-hover);
}

.KogButtonLegacy--danger:active:not(:disabled),
.KogButtonLegacy--danger:active:not([aria-disabled='true']) {
  color: var(--kog-button-danger-label-active);
  background: var(--kog-button-danger-background-active);
  border-color: var(--kog-button-danger-border-active);
}

.KogButtonLegacy--link {
  color: var(--kog-text-brand);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
  transition: none;
}

.KogButtonLegacy--link:disabled,
.KogButtonLegacy--link[aria-disabled='true'] {
  color: var(--kogPlatformGray057);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
}

.KogButtonLegacy--link:focus {
  color: var(--kog-text-brand); /* for a tag */
}

.KogButtonLegacy--link:hover:not(:disabled),
.KogButtonLegacy--link:hover:not([aria-disabled='true']) {
  color: var(--kog-button-default-label-hover); /* for a tag */
  background: var(--kogTransparent);
}

.KogButtonLegacy--link:active:not(:disabled),
.KogButtonLegacy--link:active:not([aria-disabled='true']) {
  color: var(--kog-button-default-label-hover);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
}

a.KogButtonLegacy--link {
  text-decoration: underline;
}

a.KogButtonLegacy.KogButtonLegacy--link {
  text-decoration: underline;
}

.KogButtonLegacy--subtleLink {
  color: var(--kogPlatformGray031);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
}

.KogButtonLegacy--subtleLink:focus {
  color: var(--kogPlatformGray031); /* for a tag */
}

.KogButtonLegacy--subtleLink:disabled,
.KogButtonLegacy--subtleLink[aria-disabled='true'] {
  color: var(--kogPlatformGray057);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
}

.KogButtonLegacy--subtleLink:hover:not(:disabled),
.KogButtonLegacy--subtleLink:hover:not([aria-disabled='true']) {
  color: var(--kogPlatformGray009); /* for a tag */
  background: var(--kogTransparent);
}

.KogButtonLegacy--subtleLink:active:not(:disabled),
.KogButtonLegacy--subtleLink:active:not([aria-disabled='true']) {
  color: var(--kogPlatformGray009);
  background: var(--kogTransparent);
  border-color: var(--kogTransparent);
}

.KogButtonLegacy--noStyle {
  padding: 0;
  background: transparent;
  border: none;
}

.KogButtonLegacy--l {
  min-width: 130px;
  height: 48px;
  padding: 12px 32px;
  font-size: 18px;
}

.KogButtonLegacy--s {
  min-width: 85px;
  height: 32px;
  padding: 4px 16px;
}

.KogButtonLegacy--xs {
  min-width: 85px;
  height: 24px;
  padding: 2px 12px;

  font-size: var(--kog-font-size-small);
  line-height: 16px;
}

.KogButtonLegacy--icon {
  min-width: auto;
  border: 1px none transparent;
}

@keyframes primaryButtonPulse {
  from {
    background-color: var(--kog-background-brand-900);
    border-color: var(--kog-border-brand-900);
    box-shadow: 0 0 9px var(--kogShadow020);
  }

  50% {
    background-color: var(--kog-background-brand-600);
    border-color: var(--kog-background-brand-600);
    box-shadow: 0 0 18px var(--kog-background-brand-600);
  }

  to {
    background-color: var(--kog-background-brand-900);
    border-color: var(--kog-border-brand-900);
    box-shadow: 0 0 9px var(--kogShadow020);
  }
}
