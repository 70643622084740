.KogEmoji {
  font-size: 18px;
  font-style: normal;
  vertical-align: middle;
}

.KogEmoji--medium {
  font-size: 24px;
}

.KogEmoji--large {
  font-size: 30px;
}

.KogEmoji--tada::after {
  content: '\1F389';
}

.KogEmoji--disappointed::after {
  content: '\1f61e';
}

.KogEmoji--hourglass::after {
  content: '\231B';
}

.KogEmoji--noFace::after {
  content: '\1F636';
}

.KogEmoji--wave::after {
  content: '\1F44B';
}

.KogEmoji--seeNoEvil::after {
  content: '\1F648';
}

.KogEmoji--fingerUp::after {
  content: '\261D';
}
