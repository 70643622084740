.paperLook {
  background: var(--kogPlatformWhite);
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.cursorGrab {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.cursorGrab:active {
  cursor: grabbing;
}

.visibilityHidden {
  visibility: hidden;
}

.anchor-unstyled {
  color: var(--kogPlatformGray000);
}

.anchor-unstyled:hover {
  color: var(--kogPlatformGray000);
  text-decoration: none;
}

.anchor-fully-unstyled,
.anchor-fully-unstyled:hover,
.anchor-fully-unstyled:active,
.anchor-fully-unstyled:focus,
.anchor-fully-unstyled:visited {
  color: var(--kogPlatformGray000);
  text-decoration: none;
}

.TableActionIcon {
  cursor: pointer;
  font-size: 1.5em;
  opacity: 0.7;
}

.TableActionIcon:hover {
  opacity: 0.9;
}

.opacity05 {
  opacity: 0.5;
}
