/* stylelint-disable plugin/selector-bem-pattern */

.KogRoundedDropdownItem {
  cursor: pointer;

  position: relative;

  display: block;

  margin: 2px 0;
  padding: 12px 16px;

  font-style: normal;
  color: var(--kog-text-default);
  text-decoration: none;

  background-color: var(--kog-background-default-0);
}

.KogRoundedDropdownItem:hover {
  background-color: var(--kog-list-item-hover-background);
}

.KogRoundedDropdownItem--selected {
  background-color: var(--kog-list-item-selected-background);
}

.KogRoundedDropdownItem--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.KogRoundedDropdownItem-selectedBar {
  position: absolute;
  top: 0;
  left: 0;

  width: 3px;
  height: 100%;

  background-color: var(--kog-list-item-selected-border);
  border-radius: 0 3px 3px 0;
}
