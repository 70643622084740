.absoluteContainer {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.positionAbsolute-top {
  top: 0;
}

.positionAbsolute-right {
  right: 0;
}

.positionAbsolute-bottom {
  bottom: 0;
}

.positionAbsolute-left {
  left: 0;
}

.positionAbsolute-topLeft {
  top: 0;
  left: 0;
}

.positionAbsolute-topRight {
  top: 0;
  right: 0;
}

.positionAbsolute-bottomRight {
  right: 0;
  bottom: 0;
}

.positionAbsolute-bottomLeft {
  bottom: 0;
  left: 0;
}

.positionAbsolute-centerVertically {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.positionAbsolute-centerHorizontally {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.positionAbsolute-center {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
