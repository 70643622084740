.margin-0 {
  margin: 0;
}

.margin-auto {
  margin: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

/* 8 pt Design System spacing classes
 --space-xxs: 4px
 --space-xs: 8px
 --space-s: 12px
 --space-m: 16px
 --space-l: 24px
 --space-xl: 32px
 --space-xxl: 40px
 --space-xxxl: 56px
*/

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-s {
  margin: var(--space-s);
}

.margin-m {
  margin: var(--space-m);
}

.margin-l {
  margin: var(--space-l);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-s {
  margin-top: var(--space-s);
}

.margin-top-m {
  margin-top: var(--space-m);
}

.margin-top-l {
  margin-top: var(--space-l);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-s {
  margin-right: var(--space-s);
}

.margin-right-m {
  margin-right: var(--space-m);
}

.margin-right-l {
  margin-right: var(--space-l);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-s {
  margin-bottom: var(--space-s);
}

.margin-bottom-m {
  margin-bottom: var(--space-m);
}

.margin-bottom-l {
  margin-bottom: var(--space-l);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-s {
  margin-left: var(--space-s);
}

.margin-left-m {
  margin-left: var(--space-m);
}

.margin-left-l {
  margin-left: var(--space-l);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.padd-0 {
  padding: 0;
}

.padd-xxs {
  padding: var(--space-xxs);
}

.padd-xs {
  padding: var(--space-xs);
}

.padd-s {
  padding: var(--space-s);
}

.padd-m {
  padding: var(--space-m);
}

.padd-l {
  padding: var(--space-l);
}

.padd-xl {
  padding: var(--space-xl);
}

.padd-xxl {
  padding: var(--space-xxl);
}

.padd-xxxl {
  padding: var(--space-xxxl);
}

.padd-top-0 {
  padding-top: 0;
}

.padd-top-xxs {
  padding-top: var(--space-xxs);
}

.padd-top-xs {
  padding-top: var(--space-xs);
}

.padd-top-s {
  padding-top: var(--space-s);
}

.padd-top-m {
  padding-top: var(--space-m);
}

.padd-top-l {
  padding-top: var(--space-l);
}

.padd-top-xl {
  padding-top: var(--space-xl);
}

.padd-top-xxl {
  padding-top: var(--space-xxl);
}

.padd-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padd-right-0 {
  padding-right: 0;
}

.padd-right-xxs {
  padding-right: var(--space-xxs);
}

.padd-right-xs {
  padding-right: var(--space-xs);
}

.padd-right-s {
  padding-right: var(--space-s);
}

.padd-right-m {
  padding-right: var(--space-m);
}

.padd-right-l {
  padding-right: var(--space-l);
}

.padd-right-xl {
  padding-right: var(--space-xl);
}

.padd-right-xxl {
  padding-right: var(--space-xxl);
}

.padd-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padd-bottom-0 {
  padding-bottom: 0;
}

.padd-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padd-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padd-bottom-s {
  padding-bottom: var(--space-s);
}

.padd-bottom-m {
  padding-bottom: var(--space-m);
}

.padd-bottom-l {
  padding-bottom: var(--space-l);
}

.padd-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padd-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padd-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padd-left-0 {
  padding-left: 0;
}

.padd-left-xxs {
  padding-left: var(--space-xxs);
}

.padd-left-xs {
  padding-left: var(--space-xs);
}

.padd-left-s {
  padding-left: var(--space-s);
}

.padd-left-m {
  padding-left: var(--space-m);
}

.padd-left-l {
  padding-left: var(--space-l);
}

.padd-left-xl {
  padding-left: var(--space-xl);
}

.padd-left-xxl {
  padding-left: var(--space-xxl);
}

.padd-left-xxxl {
  padding-left: var(--space-xxxl);
}

.gap-0 {
  gap: 0;
}

.gap-xxs {
  gap: var(--space-xxs);
}

.gap-xs {
  gap: var(--space-xs);
}

.gap-s {
  gap: var(--space-s);
}

.gap-m {
  gap: var(--space-m);
}

.gap-l {
  gap: var(--space-l);
}

.gap-xl {
  gap: var(--space-xl);
}

.gap-xxl {
  gap: var(--space-xxl);
}

.gap-xxxl {
  gap: var(--space-xxxl);
}

.gap-col-xxs {
  column-gap: var(--space-xxs);
}

.gap-col-xs {
  column-gap: var(--space-xs);
}

.gap-col-s {
  column-gap: var(--space-s);
}

.gap-col-m {
  column-gap: var(--space-m);
}

.gap-col-l {
  column-gap: var(--space-l);
}

.gap-col-xl {
  column-gap: var(--space-xl);
}

.gap-col-xxl {
  column-gap: var(--space-xxl);
}

.gap-col-xxxl {
  column-gap: var(--space-xxxl);
}

.gap-row-xxs {
  row-gap: var(--space-xxs);
}

.gap-row-xs {
  row-gap: var(--space-xs);
}

.gap-row-s {
  row-gap: var(--space-s);
}

.gap-row-m {
  row-gap: var(--space-m);
}

.gap-row-l {
  row-gap: var(--space-l);
}

.gap-row-xl {
  row-gap: var(--space-xl);
}

.gap-row-xxl {
  row-gap: var(--space-xxl);
}

.gap-row-xxxl {
  row-gap: var(--space-xxxl);
}
