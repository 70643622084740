/* stylelint-disable declaration-property-value-whitelist */

:root {
  /* Design System constants */
  --space-unit: 16px;
  --space-xxs: calc(0.25 * var(--space-unit)); /* 4px */
  --space-xs: calc(0.5 * var(--space-unit)); /* 8px */
  --space-s: calc(0.75 * var(--space-unit)); /* 12px */
  --space-m: var(--space-unit); /* 16px */
  --space-l: calc(1.5 * var(--space-unit)); /* 24px */
  --space-xl: calc(2 * var(--space-unit)); /* 32px */
  --space-xxl: calc(2.5 * var(--space-unit)); /* 40px */
  --space-xxxl: calc(3.5 * var(--space-unit)); /* 56px */
  --space-xxxxl: calc(4 * var(--space-unit)); /* 64px */

  /* Fixed height from navbar */
  --study-menu-height: 104px;
  --full-page-navbar-height: var(--space-xxxl);
  --study-rail-menu-width: 64px;
  --study-rail-menu-collapsed-width: 44px;
  --study-rail-slider-width: 328px;
  --off-canvas-width: 350px;
  --publishing-navbar-height: 50px;
}

/* stylelint-enable */
