.ck-filtered-dropdown {
  & .ck-dropdown__panel {
    max-height: auto;

    & .ck-labeled-field-view {
      padding: var(--space-xs);
    }

    & .ck-search__results {
      overflow-y: scroll;
      border-top: 1px solid var(--kog-colors-grey-400);

      & .ck-list {
        overflow: auto;
        max-height: 30vh;
      }
    }
  }
}
