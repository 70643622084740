.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.width-25-percent {
  width: 25%;
}

.width-50-percent {
  width: 50%;
}

.height-40-percent {
  height: 40%;
}

.height-50-percent {
  height: 50%;
}
