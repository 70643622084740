.content-editable .reflection-box {
  position: relative;

  padding: var(--space-l) var(--space-l) var(--space-xxl);

  font-family: var(--kog-content-main);
  font-size: var(--kog-font-size-default);
  font-feature-settings: 'lnum';
  color: var(--kogPlatformGray018);

  background: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray077);
  border-radius: 8px;
}

.content-editable [contenteditable='true'] .reflection-box {
  margin: 0;
}

.content-editable .reflection-box .reflection-box-header {
  position: relative;
  margin-bottom: var(--space-l);
}

.content-editable .reflection-box .icon_div {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);
  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformYellowBase);
  border-radius: 50%;
}

.content-editable .reflection-box .icon_div span {
  font: var(--kog-content-fa-icon);
  font-size: 0;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content-editable .reflection-box .icon_div span::before {
  content: '\f303'; /* Icon for .fa-shapes */
  font-size: 18px;
}

.content-editable .reflection-box .reflection-box-title {
  margin-left: 56px;
}

.content-editable .reflection-box .reflection-box-title p {
  margin: 0;
  padding: 0;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-navbar-icons);
  font-weight: bold;
  line-height: 40px;
  color: var(--kogPlatformGray018);
}
