body {
  counter-reset: blank_count;
}

kog-ftbq::before {
  content: '#' counter(blank_count)
    '\00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0';
  counter-increment: blank_count;

  margin: 0.4rem;

  border: 1px solid black;
  border-radius: 5px;
}
