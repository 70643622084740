.TeacherInstructions-content li > p {
  margin-bottom: var(--space-s);
}

.TeacherInstructions-content h4 {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
}

.TeacherInstructions-content h3 {
  margin-top: 0;
}
