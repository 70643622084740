.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 100;
}

.z-index-2 {
  z-index: 200;
}

.z-index-3 {
  z-index: 300;
}

.z-index-4 {
  z-index: 400;
}

.z-index-5 {
  z-index: 500;
}

.z-index-20 {
  z-index: 10000;
}
