.divider-top {
  border-top: 1px solid var(--kogPlatformGray084);
}

.divider-top-2 {
  border-top: 2px solid var(--kogPlatformGray084);
}

.divider-right {
  border-right: 1px solid var(--kogPlatformGray084);
}

.divider-bottom {
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.divider-bottom-2 {
  border-bottom: 2px solid var(--kogPlatformGray084);
}

.divider-left {
  border-left: 1px solid var(--kogPlatformGray084);
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-standard {
  border: 1px solid var(--kogPlatformGray077);
}

.divider-menu {
  border-bottom: 1px solid var(--kogPlatformGray077);
}

.border-none {
  border: none;
}
