/*
 * Notifications
 */

#notifications .dropdown-menu a {
  white-space: normal;
}

#notifications .dropdown-menu {
  min-width: 300px;
}

#notifications p {
  padding: 3px 15px;
}

#notifications .notification-indicator {
  position: relative;
}

#notifications .notification-count {
  background-color: var(--kogPlatformRedBase);
  display: none;
  position: absolute;
  right: -13px;
  top: -8px;
}

#notifications .notification-list li.all-link {
  text-align: center;
}

/* Version 2 */

#notifications .notification-list li.notification {
  border-bottom: 1px solid var(--kogPlatformGray084);
  font-size: 11px;
  margin: 0;
  padding: 0;
}

#notifications .notification-list li.notification .notification-icon {
  float: left;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 15%;
}

#notifications .notification-list li .notification-icon-image {
  background-position: center center;
  background-repeat: no-repeat;
  color: var(--kogPlatformGray000);
  height: 30px;
  width: 100%;
}

#notifications .notification-list li.notification .notification-content {
  color: var(--kogPlatformGray000);
  float: right;
  padding-left: 5px;
  position: relative;
  width: 85%;
}

#notifications .notification-list li.notification .notification-content a {
  text-decoration: none;
}

#notifications .notification-list.dropdown-menu li.notification a {
  padding: 5px;
}

#notifications .notification-list.dropdown-menu li.notification div:hover {
  background: var(--kogPlatformBlueLighten20);
}

#notifications li.notification .timesince {
  font-size: 10px;
}

/* All versions */

#notifications .notification-list li.unseen {
  background-color: var(--kogPlatformBlueLighten20);
}

@media (min-width: 768px) and (--viewport-m) {

  .notification__text,
  .userAccount__text {
    display: none;
  }
}

/*
 * MultipleChoiceQuestion Battle notifications
 */

.notification-icon-battle-trophy::before {
  content: "\01F3C6";
  font-size: 3em;
}

.notification .subjectnode-name {
  font-weight: bold;
}
