.ck.ck-form.ck-file-download-form {
    width: auto;
    max-width: 30em;

    & fieldset {
        padding: var(--space-xxs);
        border: 1px solid var(--kog-colors-grey-400);
        border-radius: var(--space-xxs);

        & legend {
            margin: 0;
            padding: 0 4px;
            font-size: 11px;
        }

        p {
            white-space: break-spaces;
        }
    }
}
