.text-underline {
  text-decoration: underline;
}

.text-unstyled {
  text-decoration: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-medium {
  font-weight: 500;
}

.text-weight-initial {
  font-weight: initial;
}

.text-italic {
  font-style: italic;
}

.text-size-initial {
  font-size: initial;
}

.line-height-initial {
  line-height: initial;
}

.vertical-align-text-middle {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.break-word {
  word-break: break-word;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}
