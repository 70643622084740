/*
 CSS for content that is editable in the WYSIWYG editor,
 to be used on all pages consuming that content and inside
 the editor itself
*/

/****** Clearfix all content *********/

.content-editable::after {
  content: '';
  clear: both;
  display: block;
}

/****** Editor styles *********/

/* Display hidden widgets in editor */

.content-editable .cke_widget_editable.hidden-widget {
  display: block;
  height: auto;
}

div.cke_contents,
div.content-inline-preview {
  padding: 15px;
}

div.content-inline-preview {
  cursor: pointer;
}

/****** Section styles *********/

/* Section Content */

.content-editable {
  font-family: var(--kog-content-main);
  font-size: 19px;
  font-feature-settings: 'lnum';
  font-weight: 400;
  line-height: var(--space-xl);
}

/* Only here for specificity */

.content-editable blockquote {
  position: relative;

  padding-left: 25px;

  font-size: 19px;
  line-height: var(--space-xl);

  border: 0;
}

.content-editable blockquote::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 5px;
  height: 100%;

  background-color: var(--kogPlatformGray093);
  border-radius: 20px;
}

.content-editable-width {
  max-width: 710px;
}

.content-editable h1,
.content-editable h2,
.content-editable h3,
.content-editable h4,
.content-editable h5,
.content-editable h6 {
  clear: both;
  font-family: var(--kog-satoshi);
}

.content-editable h1 {
  margin-bottom: var(--space-xl);
  font-size: 40px;
}

.content-editable h2 {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-s);
  font-size: 32px;
  line-height: 40px;
}

.content-editable h3 {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-s);
  font-size: 24px;
  line-height: 1.5em;
}

.content-editable h4 {
  font-size: 18px;
}

.content-editable h5 {
  font-size: 16px;
}

.content-editable h6 {
  font-size: 14px;
}

.content-editable h4,
.content-editable h5,
.content-editable h6 {
  margin-top: var(--space-l);
  margin-bottom: var(--space-s);
  font-weight: 700;
  line-height: 24px;
}

.ingress {
  margin-bottom: var(--space-l);

  font-size: 15px;
  font-weight: normal;
  line-height: 1.4em;
  text-transform: none;
}

.content-editable p {
  margin-bottom: var(--space-xl);
  line-height: var(--space-xl);
}

.content-editable a {
  color: var(--kog-content-link-blue-2);
  text-decoration: underline;
  text-underline-offset: 2px;
}

.content-editable ul {
  margin-bottom: var(--space-xl);
}

.content-editable li > ul {
  margin-bottom: 0;
}

.content-editable ol {
  margin-bottom: var(--space-xl);
}

.content-editable li > ol {
  margin-bottom: 0;
}

.content-editable a:hover,
.content-editable a:active,
.content-editable a:focus {
  color: var(--kog-content-link-focus-blue-2);
}

.content-editable figure figcaption,
.content-editable figure.caption figcaption {
  display: block;
  font-size: var(--kog-font-size-content);
  text-align: center;
}

/* stylelint-disable */
.content-editable figure table + figcaption {
  caption-side: top;
  display: table-caption;
}
/* stylelint-enable */

.figure-with-table {
  display: table;
}

.content-editable figure figcaption sub sup,
.content-editable figure figcaption sup sub {
  margin-top: var(--space-xs);
  font-size: var(--kog-font-size-default);
  color: var(--kogPlatformGray044);
}

.content-editable .image_resized {
  height: auto;
}

.content-editable img {
  /* Override widths set on the img tag to get correct
  aspect ratios (until the max-width kicks in) */
  clear: both;
  display: block;

  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  padding-bottom: var(--space-s);
}

.content-editable img.right-small {
  clear: none;
  max-width: 300px;
  max-height: 300px;
}

.content-editable img.centered {
  float: none;
  margin: auto;
}

.content-editable img:hover {
  cursor: pointer;
}

.content-editable img.fullpreview {
  max-width: 800px !important;
  max-height: 800px !important;
}

.content-editable iframe {
  display: block;
  max-width: 100%;
  margin: 10px auto 20px;
  border: none;
}

.content-editable figure.iframe-container {
  margin-bottom: var(--space-xl);
}

/* Hidden widget */

.content-editable .hidden-widget,
.hidden-section-question {
  overflow-y: hidden;
  height: 0;
}

span.hidden-widget {
  display: none;
}

.content-editable .math-tex iframe {
  margin: auto;
}

.content-editable .italic {
  font-style: italic;
}

.content-editable pre {
  white-space: normal;
}

.content-editable code {
  white-space: pre-wrap;
}

.content-editable code.hljs {
  padding: var(--space-s);

  font-size: var(--kog-font-size-default);
  line-height: 1.3em;
  text-align: left;

  background-color: var(--kogPlatformGray096);
}
