.KogLabelLegacy {
  display: inline;

  padding: 0.2em 0.6em 0.3em;

  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: var(--kogPlatformWhite);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  border-radius: 0.25em;
}

.KogLabelLegacy:empty {
  display: none;
}

.KogLabelLegacy--default {
  background-color: var(--kogPlatformGray044);
}

.KogLabelLegacy--danger {
  background-color: var(--kogPlatformRedDarken10);
}
