:root {
  /*
  Approved colors
  */
  --kogInherit: inherit;
  --kogShadow080: color-mod(var(--kogPlatformGray018) alpha(80%));
  --kogShadow030: color-mod(var(--kogPlatformGray018) alpha(30%));
  --kogShadow020: color-mod(var(--kogPlatformGray018) alpha(20%));
  --kogShadow010: color-mod(var(--kogPlatformGray018) alpha(10%));

  /*
  Design System colors, any changes here should also be updated in
  the Storybook Design System.

  IMPORTANT: DO NOT UPDATE WITHOUT DESIGN INPUT
  */

  /* UI Blue */

  --kogPlatformBlueLighten20: #e8f3fa;
  --kogPlatformBlueLighten10: #b6d1df;
  --kogPlatformBlueBase: #3a7a96;
  --kogPlatformBlueDarken10: #22647f;
  --kogPlatformBlueDarken20: #004e68;

  /*
    kogPlatformBlueBaseSafari very slightly differs from kogPlatformBlueBase
    in order for it to be used in rules that are specific to the Safari
    browser.
    This is to prevent the css postprocessors merging two rules into one.
  */
  --kogPlatformBlueBaseSafari: #3a7a97;

  /* UI Green */
  --kogPlatformGreenLighten20: #edfaf1;
  --kogPlatformGreenLighten10: #aee1bd;
  --kogPlatformGreenBase: #6dc787;
  --kogPlatformGreenDarken10: #4d9c62;
  --kogPlatformGreenDarken20: #2d733f;

  /* UI Yellow */
  --kogPlatformYellowLighten20: #fffce8;
  --kogPlatformYellowLighten10: #fff4bb;
  --kogPlatformYellowBase: #ffe98d;
  --kogPlatformYellowDarken10: #f5d973;
  --kogPlatformYellowDarken20: #b59a43;

  /* UI Red */
  --kogPlatformRedLighten20: #fce9e6;
  --kogPlatformRedLighten10: #faaca0;
  --kogPlatformRedBase: #e56857;
  --kogPlatformRedDarken10: #c95042;
  --kogPlatformRedDarken20: #ae382d;
  --kogPlatformRedDarken30: #932019;
  --kogPlatformRedDarken40: #780004;

  /* UI Gray */
  --kogPlatformWhite: #fff;
  --kogPlatformGray098: #fbfbf9;
  --kogPlatformGray096: #f6f6f3;
  --kogPlatformGray094: #f1f0ec;
  --kogPlatformGray093: #ededeb;
  --kogPlatformGray090: #e5e5e3;
  --kogPlatformGray084: #d5d5d2;
  --kogPlatformGray077: #c4c4c2;
  --kogPlatformGray071: #b4b4b2;
  --kogPlatformGray064: #a3a3a1;
  --kogPlatformGray057: #929291;
  --kogPlatformGray051: #828280;
  --kogPlatformGray044: #71716f;
  --kogPlatformGray038: #61615f;
  --kogPlatformGray031: #50504e;
  --kogPlatformGray025: #40403d;
  --kogPlatformGray018: #2f2f2c;
  --kogPlatformGray009: #171715;
  --kogPlatformGray000: #000;

  /* End of Design System colors */

  /*
  Content specific colors (content_editable.css)
  */
  --kog-content-faded-white: rgba(255, 255, 255, 0.75);
  --kog-content-blue-dark-1: #244062;
  --kog-content-blue-1: #0d4c6e;
  --kog-content-blue-2: #13426b;
  --kog-content-blue-3: #7498b5;
  --kog-content-blue-light-1: #95b3d7;
  --kog-content-blue-light-2: #c8dcf4;
  --kog-content-blue-light-3: #ccdee5;
  --kog-content-blue-light-4: #dceef5;
  --kog-content-blue-light-5: #25b4c8;
  --kog-content-blue-light-6: #8fc9c9;
  --kog-content-blue-light-7: #c5eff7;
  --kog-content-blue-light-8: #f1f2fa;
  --kog-content-blue-light-9: #ddf4ff;
  --kog-content-blue-light-10: #a8dcf6;
  --kog-content-link-blue: #0594b4;
  --kog-content-link-blue-2: #046277;
  --kog-content-link-focus-blue: #05809c;
  --kog-content-link-focus-blue-2: #03414f;
  --kog-content-green-1: #264;
  --kog-content-green-2: #088;
  --kog-content-green-3: #9b9;
  --kog-content-green-4: #363;
  --kog-content-green-5: #474;
  --kog-content-green-6: #4f6228;
  --kog-content-green-light-1: #c2d69b;
  --kog-content-green-light-2: #e6f3cc;
  --kog-content-green-light-3: #eef5ee;
  --kog-content-green-light-4: #719382;
  --kog-content-green-light-5: #93cba1;
  --kog-content-green-light-6: #dee;
  --kog-content-green-light-7: #eef5f5;
  --kog-content-green-light-8: #bdb;
  --kog-content-green-light-9: #c8f7c5;
  --kog-content-green-light-10: #e1fbe6;
  --kog-content-green-light-11: #b9ebe0;
  --kog-content-green-dark-1: #232;
  --kog-content-green-dark-2: #132;
  --kog-content-red-dark-1: #632423;
  --kog-content-red-light-1: #d99594;
  --kog-content-red-light-2: #f5c8c7;
  --kog-content-red-light-3: #f9e8e8;
  --kog-content-red-light-4: #efcaca;
  --kog-content-red-light-5: #ffe9d9;
  --kog-content-purple-1: #661f81;
  --kog-content-purple-2: #9d89a3;
  --kog-content-purple-3: #c1b5ff;
  --kog-content-purple-4: #615892;
  --kog-content-purple-light-1: #ede;
  --kog-content-purple-light-2: #f5eef5;
  --kog-content-purple-light-3: #f1e7fe;
  --kog-content-purple-light-4: #ffe7fc;
  --kog-content-orange-1: #c2592b;
  --kog-content-orange-light-1: #edc;
  --kog-content-orange-light-2: #fff5ee;
  --kog-content-brown-1: #873b00;
  --kog-content-brown-2: #633c1a;
  --kog-content-brown-light-1: #e9c9ab;
  --kog-content-brown-light-2: #fff0e5;
  --kog-content-brown-light-3: #ffe8d1;
  --kog-content-brown-light-4: #b0a799;
  --kog-content-beige-1: #dbd9b3;
  --kog-content-yellow-light-1: #fffcd2;
  --kog-content-yellow-light-2: #feeeb6;
  --kog-content-yellow-dark-1: #ffe4a1;
  --kog-content-yellow-dark-2: #f4d998;
  --kog-content-gray-1: #564b4b;
  --kog-content-gray-2: #4c4242;
  --kog-content-gray-3: #443b3b;
  --kog-content-gray-light-1: #ecf0f1;
  --kog-content-gray-light-2: #938c8c;
  --kog-content-gray-light-3: #e2e2e0;
  --kog-content-gray-light-4: #6b6b6b;
  --kog-content-cyan-1: #abb7b7;

  /*
  Content colors to replace old platform grays
  */

  --kog-content-gray-light-3: #e4e4e2;
  --kog-content-gray-dark-1: #333;
  --kog-content-gray-dark-2: #5c5c5c;

  /*
  3rd party apps brand colors
  */
  --googleClassroomGreen: #25a667;
  --cambridgeBackgroundBlack: rgba(53, 53, 53, 0.8);
  --engageBackgroundGreen: color-mod(#b5d7ce4d alpha(30%));

  /*
  kognity.com colors
  */
  --kogComButtonGray: #2c2f2f;
  --kogComButtonGray2: #5b6063;
  --kogComDisabledButton: #c4c4c2;
  --kogComBackgroundGreen: #b5d8cf;
}
