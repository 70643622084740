.KogOverlay {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;
}

.KogOverlay--white {
  opacity: 0.8;
  background-color: var(--kogPlatformWhite);
}
