/* stylelint-disable declaration-property-value-whitelist */

/* stylelint-disable scale-unlimited/declaration-strict-value */

@font-face {
  font-family: 'Satoshi';
  font-weight: normal;
  src:
    url('../../fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: bold;
  src:
    url('../../fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  src:
    url('../../fonts/Satoshi/Satoshi-Italic.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: bold;
  font-style: italic;
  src:
    url('../../fonts/Satoshi/Satoshi-BoldItalic.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 500;
  src:
    url('../../fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 500;
  font-style: italic;
  src:
    url('../../fonts/Satoshi/Satoshi-MediumItalic.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 900;
  src:
    url('../../fonts/Satoshi/Satoshi-Black.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 900;
  font-style: italic;
  src:
    url('../../fonts/Satoshi/Satoshi-BlackItalic.woff') format('woff'),
    url('../../fonts/Satoshi/Satoshi-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: normal;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: 500;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-Medi.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: bold;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: 900;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: normal;
  font-style: italic;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-BookIta.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: 500;
  font-style: italic;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-MediIta.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: bold;
  font-style: italic;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-BoldIta.otf') format('opentype');
}

@font-face {
  font-family: 'Meta Serif Pro';
  font-weight: 900;
  font-style: italic;
  src: url('../../fonts/MetaSerifPro/MetaSerifPro-BlackIta.otf') format('opentype');
}

:root {
  /*
  Content specific fonts (content_editable.css)
  */
  --kog-satoshi: 'Satoshi', sans-serif;
  --kog-meta-serif-pro: 'Meta Serif Pro', serif;
  --kog-content-main: var(--kog-meta-serif-pro);
  --kog-content-second: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
  --kog-content-fa-icon: normal normal normal 14px/1 'Font Awesome 5 Pro';

  /*
  Font sizes
  */
  --kog-font-size-smaller: 10px;
  --kog-font-size-student-notification: 11px;
  --kog-font-size-small: 12px;
  --kog-font-size-default: 14px;
  --kog-font-size-content: 17px;
  --kog-font-size-navbar-icons: 20px;
  --kog-font-size-medium-icon: 22px;
  --kog-font-size-big-icon: 28px;
}

/* stylelint-enable */
