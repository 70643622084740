:root {
  /* HSS-specific */
  --kog-ushss-standards-ccc: #C4E182;
  --kog-ushss-standards-dci: #F8B599;
  --kog-ushss-standards-pe: #C9B6D9;
  --kog-ushss-standards-sep: #B7BCF0;
  --kog-ushss-peaubergine-50: #F5EFF8;
  --kog-ushss-peaubergine-100: #EEEAF1;
  --kog-ushss-peaubergine-200: #DCD1E6;
  --kog-ushss-peaubergine-300: #C9B6D9;
  --kog-ushss-peaubergine-400: #B89ECE;
  --kog-ushss-peaubergine-500: #A47EC4;
  --kog-ushss-peaubergine-600: #8D56BC;
  --kog-ushss-peaubergine-700: #522676;
  --kog-ushss-peaubergine-800: #3A224B;
  --kog-ushss-peaubergine-900: #2A1636;
  --kog-ushss-dciorange-50: #FFF5F1;
  --kog-ushss-dciorange-100: #FDEFEA;
  --kog-ushss-dciorange-200: #FAD2C0;
  --kog-ushss-dciorange-300: #F8B599;
  --kog-ushss-dciorange-400: #F4946B;
  --kog-ushss-dciorange-500: #FE783F;
  --kog-ushss-dciorange-600: #E66832;
  --kog-ushss-dciorange-700: #D55E2B;
  --kog-ushss-dciorange-800: #C35526;
  --kog-ushss-dciorange-900: #A6451B;
  --kog-ushss-sepblue-50: #F4F5FD;
  --kog-ushss-sepblue-100: #E9EBFB;
  --kog-ushss-sepblue-200: #DDE0F8;
  --kog-ushss-sepblue-300: #D2D5F5;
  --kog-ushss-sepblue-400: #C6CAF3;
  --kog-ushss-sepblue-500: #B7BCF0;
  --kog-ushss-sepblue-600: #9FA6EB;
  --kog-ushss-sepblue-700: #878FE6;
  --kog-ushss-sepblue-800: #6F78E1;
  --kog-ushss-sepblue-900: #5761DB;
  --kog-ushss-cccgreen-50: #F6FBEF;
  --kog-ushss-cccgreen-100: #EDF8DF;
  --kog-ushss-cccgreen-200: #E3F4CF;
  --kog-ushss-cccgreen-300: #D9F0BF;
  --kog-ushss-cccgreen-400: #CBEDA6;
  --kog-ushss-cccgreen-500: #C4E182;
  --kog-ushss-cccgreen-600: #B4D974;
  --kog-ushss-cccgreen-700: #A0CF66;
  --kog-ushss-cccgreen-800: #8BC558;
  --kog-ushss-cccgreen-900: #75BB4A;

  /* Text */
  --kog-text-default: var(--kog-colors-grey-900);
  --kog-text-muted: var(--kog-colors-grey-700);
  --kog-text-disabled: var(--kog-colors-grey-500);
  --kog-text-soft: var(--kog-colors-grey-300);
  --kog-text-hard: var(--kog-colors-grey-0);
  --kog-text-link: var(--kog-colors-aubergine-700);
  --kog-text-brand: var(--kog-colors-aubergine-900);
  --kog-text-brand-muted: var(--kog-colors-aubergine-700);
  --kog-text-brand-hard: var(--kog-colors-aubergine-50);
  --kog-text-brand-soft: var(--kog-colors-aubergine-500);
  --kog-text-success: var(--kog-colors-green-900);
  --kog-text-success-muted: var(--kog-colors-green-700);
  --kog-text-success-hard: var(--kog-colors-green-50);
  --kog-text-success-bright: var(--kog-colors-green-500);
  --kog-text-accent: var(--kog-colors-yellow-900);
  --kog-text-accent-muted: var(--kog-colors-yellow-800);
  --kog-text-accent-hard: var(--kog-colors-yellow-50);
  --kog-text-warning: var(--kog-colors-orange-900);
  --kog-text-warning-muted: var(--kog-colors-orange-800);
  --kog-text-warning-hard: var(--kog-colors-orange-50);
  --kog-text-danger: var(--kog-colors-pink-900);
  --kog-text-danger-muted: var(--kog-colors-pink-700);
  --kog-text-danger-hard: var(--kog-colors-pink-50);

  /* Background */
  --kog-background-default-0: var(--kog-colors-grey-0);
  --kog-background-default-50: var(--kog-colors-grey-50);
  --kog-background-default-100: var(--kog-colors-grey-100);
  --kog-background-default-200: var(--kog-colors-grey-200);
  --kog-background-default-300: var(--kog-colors-grey-300);
  --kog-background-default-400: var(--kog-colors-grey-400);
  --kog-background-default-500: var(--kog-colors-grey-500);
  --kog-background-default-600: var(--kog-colors-grey-600);
  --kog-background-default-700: var(--kog-colors-grey-700);
  --kog-background-default-900: var(--kog-colors-grey-900);
  --kog-background-brand-50: var(--kog-colors-aubergine-50);
  --kog-background-brand-100: var(--kog-colors-aubergine-100);
  --kog-background-brand-200: var(--kog-colors-aubergine-200);
  --kog-background-brand-300: var(--kog-colors-aubergine-300);
  --kog-background-brand-400: var(--kog-colors-aubergine-400);
  --kog-background-brand-500: var(--kog-colors-aubergine-500);
  --kog-background-brand-600: var(--kog-colors-aubergine-600);
  --kog-background-brand-700: var(--kog-colors-aubergine-700);
  --kog-background-brand-800: var(--kog-colors-aubergine-800);
  --kog-background-brand-900: var(--kog-colors-aubergine-900);
  --kog-background-accent-50: var(--kog-colors-yellow-50);
  --kog-background-accent-100: var(--kog-colors-yellow-100);
  --kog-background-accent-200: var(--kog-colors-yellow-200);
  --kog-background-accent-300: var(--kog-colors-yellow-300);
  --kog-background-accent-400: var(--kog-colors-yellow-400);
  --kog-background-accent-500: var(--kog-colors-yellow-500);
  --kog-background-accent-700: var(--kog-colors-yellow-700);
  --kog-background-accent-800: var(--kog-colors-yellow-800);
  --kog-background-accent-900: var(--kog-colors-yellow-900);
  --kog-background-success-50: var(--kog-colors-green-50);
  --kog-background-success-100: var(--kog-colors-green-100);
  --kog-background-success-200: var(--kog-colors-green-200);
  --kog-background-success-300: var(--kog-colors-green-300);
  --kog-background-success-400: var(--kog-colors-green-400);
  --kog-background-success-500: var(--kog-colors-green-500);
  --kog-background-success-700: var(--kog-colors-green-700);
  --kog-background-success-800: var(--kog-colors-green-800);
  --kog-background-success-900: var(--kog-colors-green-900);
  --kog-background-warning-50: var(--kog-colors-orange-50);
  --kog-background-warning-100: var(--kog-colors-orange-100);
  --kog-background-warning-200: var(--kog-colors-orange-200);
  --kog-background-warning-300: var(--kog-colors-orange-300);
  --kog-background-warning-400: var(--kog-colors-orange-400);
  --kog-background-warning-500: var(--kog-colors-orange-500);
  --kog-background-warning-700: var(--kog-colors-orange-700);
  --kog-background-warning-800: var(--kog-colors-orange-800);
  --kog-background-warning-900: var(--kog-colors-orange-900);
  --kog-background-danger-50: var(--kog-colors-pink-50);
  --kog-background-danger-100: var(--kog-colors-pink-100);
  --kog-background-danger-200: var(--kog-colors-pink-200);
  --kog-background-danger-300: var(--kog-colors-pink-300);
  --kog-background-danger-400: var(--kog-colors-pink-400);
  --kog-background-danger-500: var(--kog-colors-pink-500);
  --kog-background-danger-600: var(--kog-colors-pink-600);
  --kog-background-danger-700: var(--kog-colors-pink-700);
  --kog-background-danger-800: var(--kog-colors-pink-800);
  --kog-background-danger-900: var(--kog-colors-pink-900);

  /* Border */
  --kog-border-default-0: var(--kog-colors-grey-0);
  --kog-border-default-50: var(--kog-colors-grey-50);
  --kog-border-default-100: var(--kog-colors-grey-100);
  --kog-border-default-200: var(--kog-colors-grey-200);
  --kog-border-default-300: var(--kog-colors-grey-300);
  --kog-border-default-400: var(--kog-colors-grey-400);
  --kog-border-default-500: var(--kog-colors-grey-500);
  --kog-border-default-600: var(--kog-colors-grey-600);
  --kog-border-default-700: var(--kog-colors-grey-700);
  --kog-border-default-900: var(--kog-colors-grey-900);
  --kog-border-brand-50: var(--kog-colors-aubergine-50);
  --kog-border-brand-200: var(--kog-colors-aubergine-200);
  --kog-border-brand-400: var(--kog-colors-aubergine-400);
  --kog-border-brand-500: var(--kog-colors-aubergine-500);
  --kog-border-brand-600: var(--kog-colors-aubergine-600);
  --kog-border-brand-700: var(--kog-colors-aubergine-700);
  --kog-border-brand-800: var(--kog-colors-aubergine-800);
  --kog-border-brand-900: var(--kog-colors-aubergine-900);
  --kog-border-success-50: var(--kog-colors-green-50);
  --kog-border-success-200: var(--kog-colors-green-200);
  --kog-border-success-300: var(--kog-colors-green-300);
  --kog-border-success-400: var(--kog-colors-green-400);
  --kog-border-success-500: var(--kog-colors-green-500);
  --kog-border-success-600: var(--kog-colors-green-600);
  --kog-border-success-700: var(--kog-colors-green-700);
  --kog-border-success-800: var(--kog-colors-green-800);
  --kog-border-success-900: var(--kog-colors-green-900);
  --kog-border-accent-50: var(--kog-colors-yellow-50);
  --kog-border-accent-200: var(--kog-colors-yellow-200);
  --kog-border-accent-300: var(--kog-colors-yellow-300);
  --kog-border-accent-400: var(--kog-colors-yellow-400);
  --kog-border-accent-500: var(--kog-colors-yellow-500);
  --kog-border-accent-600: var(--kog-colors-yellow-600);
  --kog-border-accent-900: var(--kog-colors-yellow-900);
  --kog-border-warning-50: var(--kog-colors-orange-50);
  --kog-border-warning-200: var(--kog-colors-orange-200);
  --kog-border-warning-400: var(--kog-colors-orange-400);
  --kog-border-warning-600: var(--kog-colors-orange-600);
  --kog-border-warning-900: var(--kog-colors-orange-900);
  --kog-border-danger-50: var(--kog-colors-pink-50);
  --kog-border-danger-100: var(--kog-colors-pink-100);
  --kog-border-danger-200: var(--kog-colors-pink-200);
  --kog-border-danger-300: var(--kog-colors-pink-300);
  --kog-border-danger-400: var(--kog-colors-pink-400);
  --kog-border-danger-500: var(--kog-colors-pink-500);
  --kog-border-danger-600: var(--kog-colors-pink-600);
  --kog-border-danger-700: var(--kog-colors-pink-700);
  --kog-border-danger-800: var(--kog-colors-pink-800);
  --kog-border-danger-900: var(--kog-colors-pink-900);

  /* Dividers */
  --kog-dividers-default: var(--kog-border-default-300);
  --kog-dividers-soft: var(--kog-border-default-100);
  --kog-dividers-hard: var(--kog-border-default-600);
  --kog-dividers-contrast: var(--kog-border-default-900);
  --kog-dividers-contrastinverted: var(--kog-border-default-0);

  /* Navigation buttons */
  --kog-navigation-background-translucent: var(--kog-colors-alpha-500);
  --kog-navigation-background-translucent-hover: var(--kog-colors-alpha-500);
  --kog-navigation-background-default: var(--kog-background-brand-900);
  --kog-navigation-background-default-hover: var(--kog-background-brand-600);
  --kog-navigation-background-default-focus: var(--kog-background-brand-900);
  --kog-navigation-background-default-active: var(--kog-background-brand-700);
  --kog-navigation-button-default: var(--kog-text-hard);
  --kog-navigation-button-hover: var(--kog-text-brand-hard);
  --kog-navigation-button-focus: var(--kog-text-brand-hard);
  --kog-navigation-button-active: var(--kog-text-brand-hard);
  --kog-navigation-button-disabled: var(--kog-text-disabled);
  --kog-navigation-border-default: var(--kog-border-default-0);
  --kog-navigation-border-brand: var(--kog-border-success-400);
  --kog-navigation-border-subnav: var(--kog-colors-alpha-500);
  --kog-navigation-icon-background: var(--kog-colors-grey-100);
  --kog-navigation-icon-default: var(--kog-text-hard);
  --kog-navigation-icon-hover: var(--kog-text-brand-hard);
  --kog-navigation-icon-focus: var(--kog-text-brand-hard);
  --kog-navigation-icon-active: var(--kog-text-brand-hard);
  --kog-navigation-icon-disabled: var(--kog-text-disabled);
  --kog-navigation-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

  /* Interactive list items */
  --kog-list-item-hover-background: var(--kog-colors-grey-100);
  --kog-list-item-selected-background: var(--kog-colors-aubergine-100);
  --kog-list-item-selected-border: var(--kog-colors-aubergine-600);
  --kog-list-item-selected-hover-background: var(--kog-colors-aubergine-200);
  --kog-menu-item-hover-background: var(--kog-background-brand-900);
  --kog-menu-item-hover: var(--kog-text-brand-hard);

  /* Misc */
  --kog-teacher-instructions-background: var(--kog-colors-yellow-100);
  --kog-rubric-background: var(--kog-colors-grey-100);
  --kog-skeleton-background: var(--kogSkeleton);

  /* Focus */
  --kog-focus-outline: var(--kog-border-brand-600);
  --kog-focus-outline-safari: #8D56BC;

  /* Notification items */
  --kog-notification-item-unseen-border: var(--kog-colors-aubergine-600);
  --kog-notification-icon-color: var(--kog-colors-aubergine-300);
  --kog-notification-item-border: var(--kog-colors-grey-400);

  /* Link */
  --kog-link-color: var(--kog-colors-aubergine-700);
  --kog-link-hover-color: var(--kog-colors-aubergine-900);

  /* Borders */
  --kog-component-outline-border-color: var(--kog-colors-grey-400);

  /* Icons */
  --kog-card-icon-background: var(--kog-background-brand-100);
  --kog-card-icon-color: var(--kog-text-default);
  --kog-card-hard-icon-background: var(--kog-background-brand-900);
  --kog-card-hard-icon-color: var(--kog-text-brand-hard);

  /* footer */
  --kog-footer-background: var(--kog-colors-grey-300);

  /* alert */
  --kog-alert-info-background: var(--kog-colors-aubergine-100);
  --kog-alert-success-background: var(--kog-colors-green-200);
  --kog-alert-warning-background: var(--kog-colors-yellow-200);
  --kog-alert-error-background: var(--kog-background-danger-200);

  /* checkbox */
  --kog-checkbox-label-color: var(--kog-text-default);
  --kog-checkbox-label-disabled: var(--kog-text-disabled);
  --kog-checkbox-checked-border: var(--kog-icon-default);
  --kog-checkbox-unchecked-border: var(--kog-icon-muted);
  --kog-checkbox-border-disabled: var(--kog-icon-disabled);
  --kog-checkbox-icon-color: var(--kog-text-brand);
  --kog-checkbox-icon-color-disabled: var(--kog-icon-disabled);
  --kog-checkbox-hover: var(--kog-colors-grey-200);
  --kog-checkbox-fill: var(--kog-icon-default);

  /* radio button */
  --kog-radio-color: var(--kog-icon-default);
  --kog-radio-border: var(--kog-icon-default);
  --kog-radio-label: var(--kog-text-default);
  --kog-radio-border-unckecked: var(--kog-icon-muted);
  --kog-radio-color-disabled: var(--kog-text-disabled);
  --kog-radio-label-disabled: var(--kog-text-disabled);
  --kog-radio-border-disabled: var(--kog-icon-disabled);
  --kog-radio-hover: var(--kog-colors-grey-300);
  --kog-radio-focused-outline: var(--kog-focus-outline);
  --kog-radio-focused-outline-safari: var(--kog-focus-outline-safari);

  /* button */

  /* default-hollow */
  --kog-button-default-hollow-background-default: var(--kog-colors-alpha-0);
  --kog-button-default-hollow-background-hover: var(--kog-colors-alpha-50);
  --kog-button-default-hollow-background-focus: var(--kog-background-brand-50);
  --kog-button-default-hollow-background-active: var(--kog-background-brand-50);
  --kog-button-default-hollow-background-disabled: var(--kog-background-default-100);
  --kog-button-default-hollow-background-loading: var(--kog-colors-alpha-0);
  --kog-button-default-hollow-border-default: var(--kog-border-default-900);
  --kog-button-default-hollow-border-hover: var(--kog-border-default-900);
  --kog-button-default-hollow-border-focus: var(--kog-border-brand-900);
  --kog-button-default-hollow-border-active: var(--kog-border-brand-400);
  --kog-button-default-hollow-border-disabled: var(--kog-border-default-300);
  --kog-button-default-hollow-border-loading: var(--kog-border-default-900);
  --kog-button-default-hollow-label-default: var(--kog-text-default);
  --kog-button-default-hollow-label-hover: var(--kog-text-default);
  --kog-button-default-hollow-label-focus: var(--kog-text-default);
  --kog-button-default-hollow-label-active: var(--kog-text-brand-muted);
  --kog-button-default-hollow-label-disabled: var(--kog-text-disabled);
  --kog-button-default-hollow-label-loading: var(--kog-colors-aubergine-500);
  --kog-button-default-hollow-icon-default: var(--kog-text-default);
  --kog-button-default-hollow-icon-hover: var(--kog-text-default);
  --kog-button-default-hollow-icon-focus: var(--kog-text-default);
  --kog-button-default-hollow-icon-active: var(--kog-text-brand-muted);
  --kog-button-default-hollow-icon-disabled: var(--kog-text-disabled);

  /* default fill */
  --kog-button-default-fill-background-default: var(--kog-background-default-0);
  --kog-button-default-fill-background-hover: var(--kog-colors-alpha-hard-700);
  --kog-button-default-fill-background-focus: var(--kog-background-default-0);
  --kog-button-default-fill-background-active: var(--kog-background-brand-50);
  --kog-button-default-fill-background-disabled: var(--kog-colors-grey-100);
  --kog-button-default-fill-background-loading: var(--kog-background-default-0);
  --kog-button-default-fill-border-default: var(--kog-border-default-900);
  --kog-button-default-fill-border-hover: var(--kog-border-default-900);
  --kog-button-default-fill-border-focus: var(--kog-border-brand-900);
  --kog-button-default-fill-border-active: var(--kog-border-brand-400);
  --kog-button-default-fill-border-disabled: var(--kog-colors-grey-300);
  --kog-button-default-fill-border-loading: var(--kog-border-default-900);
  --kog-button-default-fill-label-default: var(--kog-text-default);
  --kog-button-default-fill-label-hover: var(--kog-text-default);
  --kog-button-default-fill-label-focus: var(--kog-text-default);
  --kog-button-default-fill-label-active: var(--kog-text-brand-muted);
  --kog-button-default-fill-label-disabled: var(--kog-text-disabled);
  --kog-button-default-fill-icon-default: var(--kog-text-default);
  --kog-button-default-fill-icon-hover: var(--kog-text-default);
  --kog-button-default-fill-icon-focus: var(--kog-text-default);
  --kog-button-default-fill-icon-active: var(--kog-text-brand-muted);
  --kog-button-default-fill-icon-disabled: var(--kog-text-disabled);

  /* primary */
  --kog-button-primary-background-default: var(--kog-background-brand-900);
  --kog-button-primary-background-hover: var(--kog-background-brand-700);
  --kog-button-primary-background-focus: var(--kog-background-brand-900);
  --kog-button-primary-background-active: var(--kog-background-brand-900);
  --kog-button-primary-background-disabled: var(--kog-background-default-100);
  --kog-button-primary-background-loading: var(--kog-background-brand-900);
  --kog-button-primary-border-default: var(--kog-border-brand-900);
  --kog-button-primary-border-hover: var(--kog-border-brand-700);
  --kog-button-primary-border-focus: var(--kog-border-brand-900);
  --kog-button-primary-border-active: var(--kog-border-brand-900);
  --kog-button-primary-border-disabled: var(--kog-border-default-300);
  --kog-button-primary-border-loading: var(--kog-border-brand-900);
  --kog-button-primary-label-default: var(--kog-text-hard);
  --kog-button-primary-label-hover: var(--kog-text-brand-hard);
  --kog-button-primary-label-focus: var(--kog-text-brand-hard);
  --kog-button-primary-label-active: var(--kog-text-brand-hard);
  --kog-button-primary-label-disabled: var(--kog-text-disabled);
  --kog-button-primary-label-loading: var(--kog-colors-aubergine-300);
  --kog-button-primary-icon-default: var(--kog-text-hard);
  --kog-button-primary-icon-hover: var(--kog-text-brand-hard);
  --kog-button-primary-icon-focus: var(--kog-text-brand-hard);
  --kog-button-primary-icon-active: var(--kog-text-brand-hard);
  --kog-button-primary-icon-disabled: var(--kog-text-disabled);

  /* accent */
  --kog-button-accent-background-default: var(--kog-background-accent-500);
  --kog-button-accent-background-hover: var(--kog-background-accent-300);
  --kog-button-accent-background-focus: var(--kog-background-accent-500);
  --kog-button-accent-background-active: var(--kog-background-accent-200);
  --kog-button-accent-background-disabled: var(--kog-background-default-100);
  --kog-button-accent-loading-background: var(--kog-background-accent-400);
  --kog-button-accent-border-default: var(--kog-border-accent-500);
  --kog-button-accent-border-hover: var(--kog-border-accent-300);
  --kog-button-accent-border-active: var(--kog-border-accent-200);
  --kog-button-accent-border-disabled: var(--kog-border-default-300);
  --kog-button-accent-border-loading: var(--kog-border-accent-400);
  --kog-button-accent-label-default: var(--kog-text-default);
  --kog-button-accent-label-hover: var(--kog-text-default);
  --kog-button-accent-label-focus: var(--kog-text-default);
  --kog-button-accent-label-active: var(--kog-text-accent);
  --kog-button-accent-label-disabled: var(--kog-text-disabled);
  --kog-button-accent-label-loading: var(--kog-text-accent);
  --kog-button-accent-icon-default: var(--kog-button-accent-label-default);
  --kog-button-accent-icon-hover: var(--kog-button-accent-label-default);
  --kog-button-accent-icon-focus: var(--kog-button-accent-label-default);
  --kog-button-accent-icon-active: var(--kog-text-accent);
  --kog-button-accent-icon-disabled: var(--kog-text-disabled);

  /* danger */
  --kog-button-danger-background-default: var(--kog-background-danger-500);
  --kog-button-danger-background-hover: var(--kog-background-danger-300);
  --kog-button-danger-background-focus: var(--kog-background-danger-500);
  --kog-button-danger-background-active: var(--kog-background-danger-200);
  --kog-button-danger-background-disabled: var(--kog-background-default-100);
  --kog-button-danger-loading-background: var(--kog-background-danger-400);
  --kog-button-danger-border-default: var(--kog-border-danger-500);
  --kog-button-danger-border-hover: var(--kog-border-danger-300);
  --kog-button-danger-border-active: var(--kog-border-danger-200);
  --kog-button-danger-border-disabled: var(--kog-border-default-300);
  --kog-button-danger-border-loading: var(--kog-border-danger-400);
  --kog-button-danger-label-default: var(--kog-text-default);
  --kog-button-danger-label-hover: var(--kog-text-default);
  --kog-button-danger-label-focus: var(--kog-text-default);
  --kog-button-danger-label-active: var(--kog-text-danger);
  --kog-button-danger-label-disabled: var(--kog-text-disabled);
  --kog-button-danger-label-loading: var(--kog-text-danger);
  --kog-button-danger-icon-default: var(--kog-button-danger-label-default);
  --kog-button-danger-icon-hover: var(--kog-button-danger-label-hover);
  --kog-button-danger-icon-focus: var(--kog-button-danger-label-focus);
  --kog-button-danger-icon-active: var(--kog-button-danger-label-active);
  --kog-button-danger-icon-disabled: var(--kog-text-disabled);

  /* positive */
  --kog-button-success-background-default: var(--kog-background-success-500);
  --kog-button-success-background-hover: var(--kog-background-success-300);
  --kog-button-success-background-focus: var(--kog-background-success-500);
  --kog-button-success-background-active: var(--kog-background-success-200);
  --kog-button-success-background-disabled: var(--kog-background-default-100);
  --kog-button-success-background-loading: var(--kog-background-success-300);
  --kog-button-success-border-default: var(--kog-border-success-500);
  --kog-button-success-border-hover: var(--kog-border-success-300);
  --kog-button-success-border-active: var(--kog-border-success-200);
  --kog-button-success-border-disabled: var(--kog-border-default-300);
  --kog-button-success-border-loading: var(--kog-border-success-300);
  --kog-button-success-label-default: var(--kog-text-default);
  --kog-button-success-label-hover: var(--kog-text-default);
  --kog-button-success-label-focus: var(--kog-text-default);
  --kog-button-success-label-active: var(--kog-text-success-muted);
  --kog-button-success-label-disabled: var(--kog-text-disabled);
  --kog-button-success-label-loading: var(--kog-text-success);
  --kog-button-success-icon-default: var(--kog-button-success-label-default);
  --kog-button-success-icon-hover: var(--kog-button-success-label-hover);
  --kog-button-success-icon-focus: var(--kog-button-success-label-focus);
  --kog-button-success-icon-active: var(--kog-button-success-label-active);
  --kog-button-success-icon-disabled: var(--kog-text-disabled);

  /* basic */
  --kog-button-basic-background-default: var(--kog-colors-alpha-0);
  --kog-button-basic-background-hover: var(--kog-colors-alpha-50);
  --kog-button-basic-background-focus: var(--kog-colors-alpha-0);
  --kog-button-basic-background-active: var(--kog-background-brand-50);
  --kog-button-basic-background-disabled: var(--kog-background-default-100);
  --kog-button-basic-background-loading: var(--kog-colors-alpha-0);
  --kog-button-basic-border-active: var(--kog-background-brand-50);
  --kog-button-basic-border-disabled: var(--kog-border-default-300);
  --kog-button-basic-label-default: var(--kog-text-default);
  --kog-button-basic-label-hover: var(--kog-text-default);
  --kog-button-basic-label-focus: var(--kog-text-default);
  --kog-button-basic-label-active: var(--kog-text-brand-muted);
  --kog-button-basic-label-disabled: var(--kog-text-disabled);
  --kog-button-basic-label-loading: var(--kog-text-default);
  --kog-button-basic-icon-default: var(--kog-text-default);
  --kog-button-basic-icon-hover: var(--kog-text-default);
  --kog-button-basic-icon-focus: var(--kog-text-default);
  --kog-button-basic-icon-active: var(--kog-text-brand-muted);
  --kog-button-basic-icon-disabled: var(--kog-text-disabled);

  /* basic-inverted */
  --kog-button-basic-inverted-background-default: var(--kog-colors-alpha-hard-0);
  --kog-button-basic-inverted-background-hover: var(--kog-colors-alpha-hard-50);
  --kog-button-basic-inverted-background-focus: var(--kog-colors-alpha-hard-0);
  --kog-button-basic-inverted-background-active: var(--kog-colors-alpha-hard-200);
  --kog-button-basic-inverted-background-disabled: var(--kog-colors-grey-100);
  --kog-button-basic-inverted-label-default: var(--kog-text-hard);
  --kog-button-basic-inverted-label-hover: var(--kog-text-hard);
  --kog-button-basic-inverted-label-focus: var(--kog-text-hard);
  --kog-button-basic-inverted-label-active: var(--kog-text-brand-hard);
  --kog-button-basic-inverted-label-disabled: var(--kog-text-disabled);
  --kog-button-basic-inverted-icon-default: var(--kog-text-hard);
  --kog-button-basic-inverted-icon-hover: var(--kog-text-hard);
  --kog-button-basic-inverted-icon-focus: var(--kog-text-hard);
  --kog-button-basic-inverted-icon-active: var(--kog-text-brand-hard);
  --kog-button-basic-inverted-icon-disabled: var(--kog-text-disabled);

  /* end button */

  /* round button */

  /* primary */
  --kog-round-button-primary-background: var(--kog-button-primary-background-default);
  --kog-round-button-primary-color: var(--kog-button-primary-label-default);
  --kog-round-button-primary-hover-background: var(--kog-button-primary-background-hover);
  --kog-round-button-primary-hover-color: var(--kog-button-primary-label-hover);
  --kog-round-button-primary-active-background: var(--kog-button-primary-background-active);
  --kog-round-button-primary-active-color: var(--kog-button-primary-label-active);
  --kog-round-button-primary-disabled-background: var(--kog-button-primary-background-disabled);
  --kog-round-button-primary-disabled-color: var(--kog-button-primary-label-disabled);

  /* accent */
  --kog-round-button-accent-background: var(--kog-button-accent-background-default);
  --kog-round-button-accent-color: var(--kog-button-accent-label-default);
  --kog-round-button-accent-hover-background: var(--kog-button-accent-background-hover);
  --kog-round-button-accent-hover-color: var(--kog-button-accent-label-hover);
  --kog-round-button-accent-active-background: var(--kog-button-accent-background-active);
  --kog-round-button-accent-active-color: var(--kog-button-accent-label-active);
  --kog-round-button-accent-disabled-background: var(--kog-button-accent-background-disabled);
  --kog-round-button-accent-disabled-color: var(--kog-button-accent-label-disabled);

  /* basic */
  --kog-round-button-basic-background: var(--kog-button-basic-background-default);
  --kog-round-button-basic-color: var(--kog-button-basic-label-default);
  --kog-round-button-basic-hover-background: var(--kog-button-basic-background-hover);
  --kog-round-button-basic-hover-color: var(--kog-button-basic-label-hover);
  --kog-round-button-basic-active-background: var(--kog-button-basic-background-active);
  --kog-round-button-basic-active-color: var(--kog-button-basic-label-active);
  --kog-round-button-basic-disabled-background: var(--kog-button-basic-background-disabled);
  --kog-round-button-basic-disabled-color: var(--kog-button-basic-label-disabled);

  /* danger */
  --kog-round-button-danger-background: var(--kog-button-danger-background-default);
  --kog-round-button-danger-color: var(--kog-button-danger-label-default);
  --kog-round-button-danger-hover-background: var(--kog-button-danger-background-hover);
  --kog-round-button-danger-hover-color: var(--kog-button-danger-label-hover);
  --kog-round-button-danger-active-background: var(--kog-button-danger-background-active);
  --kog-round-button-danger-active-color: var(--kog-button-danger-label-active);
  --kog-round-button-danger-disabled-background: var(--kog-button-danger-background-disabled);
  --kog-round-button-danger-disabled-color: var(--kog-button-danger-label-disabled);

  /* positive */
  --kog-round-button-positive-background: var(--kog-button-success-background-default);
  --kog-round-button-positive-color: var(--kog-button-success-label-default);
  --kog-round-button-positive-hover-background: var(--kog-button-success-background-hover);
  --kog-round-button-positive-hover-color: var(--kog-button-success-label-hover);
  --kog-round-button-positive-active-background: var(--kog-button-success-background-active);
  --kog-round-button-positive-active-color: var(--kog-button-success-label-active);
  --kog-round-button-positive-disabled-background: var(--kog-button-success-background-disabled);
  --kog-round-button-positive-disabled-color: var(--kog-button-success-label-disabled);

  /* default-hollow */
  --kog-round-button-default-hollow-background: var(--kog-button-default-hollow-background-default);
  --kog-round-button-default-hollow-border-color: var(--kog-button-default-hollow-border-default);
  --kog-round-button-default-hollow-color: var(--kog-button-default-hollow-label-default);
  --kog-round-button-default-hollow-hover-background: var(--kog-button-default-hollow-background-hover);
  --kog-round-button-default-hollow-hover-border-color: var(--kog-button-default-hollow-border-hover);
  --kog-round-button-default-hollow-hover-color: var(--kog-button-default-hollow-label-hover);
  --kog-round-button-default-hollow-active-background: var(--kog-button-default-hollow-background-active);
  --kog-round-button-default-hollow-active-border-color: var(--kog-button-default-hollow-border-active);
  --kog-round-button-default-hollow-active-color: var(--kog-button-default-hollow-label-active);
  --kog-round-button-default-hollow-disabled-background: var(--kog-button-default-hollow-background-disabled);
  --kog-round-button-default-hollow-disabled-border-color: var(--kog-button-default-hollow-border-disabled);
  --kog-round-button-default-hollow-disabled-color: var(--kog-button-default-hollow-label-disabled);

  /* default-fill */
  --kog-round-button-default-fill-background-default: var(--kog-button-default-fill-background-default);
  --kog-round-button-default-fill-background-hover: var(--kog-button-default-fill-background-hover);
  --kog-round-button-default-fill-background-focus: var(--kog-button-default-fill-background-focus);
  --kog-round-button-default-fill-background-active: var(--kog-button-default-fill-background-active);
  --kog-round-button-default-fill-background-disabled: var(--kog-button-default-fill-background-disabled);
  --kog-round-button-default-fill-border-default: var(--kog-button-default-fill-border-default);
  --kog-round-button-default-fill-border-hover: var(--kog-button-default-fill-border-hover);
  --kog-round-button-default-fill-border-focus: var(--kog-button-default-fill-border-focus);
  --kog-round-button-default-fill-border-active: var(--kog-button-default-fill-border-active);
  --kog-round-button-default-fill-border-disabled: var(--kog-button-default-fill-border-disabled);
  --kog-round-button-default-fill-icon-default: var(--kog-button-default-fill-icon-default);
  --kog-round-button-default-fill-icon-hover: var(--kog-button-default-fill-icon-hover);
  --kog-round-button-default-fill-icon-focus: var(--kog-button-default-fill-icon-focus);
  --kog-round-button-default-fill-icon-active: var(--kog-button-default-fill-icon-active);
  --kog-round-button-default-fill-icon-disabled: var(--kog-button-default-fill-icon-disabled);

  /* secondary-basic */
  --kog-round-button-secondary-basic-background: var(--kogTransparent);
  --kog-round-button-secondary-basic-color: var(--kog-colors-grey-900);
  --kog-round-button-secondary-basic-hover-background: color-mod(var(--kog-colors-grey-900) alpha(10%));
  --kog-round-button-secondary-basic-hover-color: var(--kog-colors-grey-900);
  --kog-round-button-secondary-basic-active-background: color-mod(var(--kog-colors-grey-900) alpha(10%));
  --kog-round-button-secondary-basic-active-color: var(--kog-colors-grey-700);
  --kog-round-button-secondary-basic-disabled-background: var(--kogTransparent);
  --kog-round-button-secondary-basic-disabled-color: var(--kog-colors-grey-700);

  /* secondary-outline */
  --kog-round-button-secondary-outline-background: var(--kogTransparent);
  --kog-round-button-secondary-outline-border-color: var(--kogTransparent);
  --kog-round-button-secondary-outline-color: var(--kog-colors-grey-900);
  --kog-round-button-secondary-outline-hover-background: color-mod(var(--kog-colors-grey-900) alpha(10%));
  --kog-round-button-secondary-outline-hover-border-color: var(--kog-colors-grey-900);
  --kog-round-button-secondary-outline-hover-color: var(--kog-colors-grey-900);
  --kog-round-button-secondary-outline-active-background: color-mod(var(--kog-colors-grey-900) alpha(10%));
  --kog-round-button-secondary-outline-active-border-color: var(--kog-colors-grey-700);
  --kog-round-button-secondary-outline-active-color: var(--kog-colors-grey-700);
  --kog-round-button-secondary-outline-disabled-background: var(--kogTransparent);
  --kog-round-button-secondary-outline-disabled-border-color: var(--kogTransparent);
  --kog-round-button-secondary-outline-disabled-color: var(--kog-colors-grey-700);

  /* basic-inverted */
  --kog-round-button-basic-inverted-background-default: var(--kog-button-basic-inverted-background-default);
  --kog-round-button-basic-inverted-background-hover: var(--kog-button-basic-inverted-background-hover);
  --kog-round-button-basic-inverted-background-focus: var(--kog-button-basic-inverted-background-focus);
  --kog-round-button-basic-inverted-background-active: var(--kog-button-basic-inverted-background-active);
  --kog-round-button-basic-inverted-background-disabled: var(--kog-button-basic-inverted-background-disabled);
  --kog-round-button-basic-inverted-icon-default: var(--kog-button-basic-inverted-icon-default);
  --kog-round-button-basic-inverted-icon-hover: var(--kog-button-basic-inverted-icon-hover);
  --kog-round-button-basic-inverted-icon-focus: var(--kog-button-basic-inverted-icon-focus);
  --kog-round-button-basic-inverted-icon-active: var(--kog-button-basic-inverted-icon-active);
  --kog-round-button-basic-inverted-icon-disabled: var(--kog-button-basic-inverted-icon-disabled);

  /* end round button */

  /* input */
  --kog-input-outline-background-color: var(--kog-colors-white);
  --kog-input-outline-disabled-background-color: var(--kog-colors-grey-300);
  --kog-input-outline-border-color: var(--kog-colors-grey-700);
  --kog-input-outline-disabled-border-color: var(--kog-colors-grey-700);
  --kog-input-outline-hover-border-color: var(--kog-colors-aubergine-700);
  --kog-input-outline-focus-border-color: var(--kog-colors-aubergine-600);
  --kog-input-outline-error-border-color: var(--kog-colors-pink-700);
  --kog-input-prominent-background-color: var(--kog-colors-aubergine-100);
  --kog-input-prominent-disabled-background-color: var(--kog-colors-grey-300);
  --kog-input-prominent-border-color: var(--kog-colors-aubergine-700);
  --kog-input-prominent-disabled-border-color: var(--kog-colors-grey-700);
  --kog-input-prominent-hover-border-color: var(--kog-colors-aubergine-700);
  --kog-input-prominent-focus-border-color: var(--kog-colors-aubergine-600);
  --kog-input-prominent-error-border-color: var(--kog-colors-pink-700);
  --kog-input-icon-color: var(--kog-text-default);
  --kog-input-placeholder-color: var(--kog-text-muted);
  --kog-input-disabled-placeholder-color: var(--kog-text-default);
  --kog-input-error-placeholder-color: var(--kog-text-default);
  --kog-input-focus-placeholder-color: var(--kog-text-default);
  --kog-input-helper-text-color: var(--kog-text-muted);
  --kog-input-error-text-color: var(--kog-colors-pink-700);

  /* tag */
  --kog-tag-color: var(--kog-text-brand);
  --kog-tag-default-background-color: var(--kog-colors-grey-300);
  --kog-tag-basic-background-color: var(--kog-colors-white);
  --kog-tag-outline-border-color: var(--kog-component-outline-border-color);
  --kog-tag-outlineInformative-background-color: var(--kog-colors-grey-300);
  --kog-tag-outlineInformative-border-color: var(--kog-colors-grey-500);
  --kog-tag-informative-background-color: var(--kog-colors-aubergine-300);
  --kog-tag-informative2-background-color: var(--kog-colors-orange-300);
  --kog-tag-informative3-background-color: var(--kog-colors-pink-300);
  --kog-tag-warning-background-color: var(--kog-colors-yellow-300);
  --kog-tag-outlineWarning-border-color: var(--kog-colors-yellow-700);
  --kog-tag-outlineWarning-background-color: var(--kog-colors-yellow-300);
  --kog-tag-positive-background-color: var(--kog-background-success-300);
  --kog-tag-negative-background-color: var(--kog-background-danger-500);
  --kog-tag-negative-color: var(--kog-text-default);
  --kog-tag-button-default-hover-border-color: var(--kog-colors-grey-900);
  --kog-tag-button-basic-hover-border-color: var(--kog-colors-grey-900);
  --kog-tag-button-outline-hover-border-color: var(--kog-colors-grey-900);
  --kog-tag-button-outlineInformative-hover-border-color: var(--kog-colors-grey-900);
  --kog-tag-button-informative-hover-border-color: var(--kog-colors-aubergine-900);
  --kog-tag-button-informative2-hover-border-color: var(--kog-colors-orange-900);
  --kog-tag-button-informative3-hover-border-color: var(--kog-colors-pink-900);
  --kog-tag-button-warning-hover-border-color: var(--kog-colors-yellow-900);
  --kog-tag-button-outlineWarning-hover-border-color: var(--kog-colors-yellow-900);
  --kog-tag-button-positive-hover-border-color: var(--kog-border-success-900);
  --kog-tag-button-negative-hover-border-color: var(--kog-border-danger-900);

  /* tag-notification */
  --kog-notification-alert-background-color: var(--kog-colors-pink-500);
  --kog-notification-online-background-color: var(--kog-background-success-300);
  --kog-notification-offline-background-color: var(--kog-colors-grey-500);
  --kog-notification-border-color: var(--kog-colors-white);
  --kog-notification-color: var(--kog-colors-aubergine-900);

  /* select */
  --kog-select-background-color: var(--kog-input-outline-background-color);
  --kog-select-disabled-background-color: var(--kog-input-outline-disabled-background-color);
  --kog-select-border-color: var(--kog-input-outline-border-color);
  --kog-select-hover-border-color: var(--kog-input-outline-hover-border-color);
  --kog-select-focus-border-color: var(--kog-input-outline-focus-border-color);
  --kog-select-error-border-color: var(--kog-input-outline-error-border-color);
  --kog-select-helper-text-color: var(--kog-input-helper-text-color);
  --kog-select-error-text-color: var(--kog-input-error-text-color);

  /* dropdown */
  --kog-dropdown-background-color: var(--kog-colors-white);
  --kog-dropdown-border-color: var(--kog-colors-grey-200);
  --kog-dropdown-box-shadow-color: var(--kogShadow020);
  --kog-dropdown-item-separator-color: var(--kog-colors-grey-400);
  --kog-dropdown-item-group-title-color: var(--kog-colors-grey-700);
  --kog-dropdown-item-hover-background: var(--kog-list-item-hover-background);
  --kog-dropdown-item-focus-background: var(--kog-list-item-hover-background);
  --kog-dropdown-item-disabled-color: var(--kog-text-disabled);
  --kog-dropdown-item-selected-background: var(--kog-list-item-selected-background);
  --kog-dropdown-menu-item-hover-background: var(--kog-menu-item-hover-background);
  --kog-dropdown-menu-item-hover-color: var(--kog-menu-item-hover);
  --kog-dropdown-divider: var(--kog-colors-grey-500);

  /* progressbar */
  --kog-progressbar-default-background: var(--kog-colors-grey-300);
  --kog-progressbar-single-primary-color: var(--kog-colors-aubergine-700);
  --kog-progressbar-single-primary-background: var(--kog-colors-grey-300);
  --kog-progressbar-single-positive-color: var(--kog-colors-green-500);
  --kog-progressbar-single-positive-background: var(--kog-colors-grey-300);
  --kog-progressbar-single-mixed-color: var(--kog-colors-green-500);
  --kog-progressbar-single-mixed-background: var(--kog-colors-pink-500);
  --kog-progressbar-single-negative-color: var(--kog-background-danger-500);
  --kog-progressbar-single-negative-background: var(--kog-colors-grey-300);
  --kog-progressbar-single-warning-color: var(--kog-colors-yellow-500);
  --kog-progressbar-single-warning-background: var(--kog-colors-grey-300);
  --kog-progressbar-double-primary-bar1-color: var(--kog-colors-aubergine-700);
  --kog-progressbar-double-primary-bar2-color: var(--kog-colors-aubergine-500);
  --kog-progressbar-double-positive-bar1-color: var(--kog-colors-green-800);
  --kog-progressbar-double-positive-bar2-color: var(--kog-colors-green-500);
  --kog-progressbar-double-mixed-bar1-color: var(--kog-colors-green-500);
  --kog-progressbar-double-mixed-bar2-color: var(--kog-background-danger-500);
  --kog-progressbar-double-warning-bar1-color: var(--kog-colors-yellow-800);
  --kog-progressbar-double-warning-bar2-color: var(--kog-colors-yellow-500);
  --kog-progressbar-double-negative-bar1-color: var(--kog-colors-pink-800);
  --kog-progressbar-double-negative-bar2-color: var(--kog-background-danger-500);

  /* shortTextQuestion */
  --kog-stq-placeholder-color: var(--kog-colors-grey-600);
  --kog-stq-color: var(--kog-text-default);
  --kog-stq-icon-color: var(--kog-colors-grey-700);
  --kog-stq-filled-icon-color: var(--kog-text-default);
  --kog-stq-gray-background-color: var(--kog-colors-grey-100);
  --kog-stq-gray-icon-background-color: var(--kog-colors-white);
  --kog-stq-white-background-color: var(--kog-colors-white);
  --kog-stq-white-icon-background-color: var(--kog-colors-grey-200);
  --kog-stq-outline-background-color: var(--kog-colors-white);
  --kog-stq-outline-border-color: var(--kog-component-outline-border-color);
  --kog-stq-outline-icon-background-color: var(--kog-colors-grey-200);
  --kog-stq-outline-filled-border-color: var(--kog-colors-grey-700);
  --kog-stq-focused-background-color: var(--kog-colors-white);
  --kog-stq-focused-icon-color: var(--kog-text-default);
  --kog-stq-focused-icon-background-color: var(--kog-colors-grey-200);
  --kog-stq-correct-background-color: var(--kog-colors-green-100);
  --kog-stq-correct-border-color: var(--kog-colors-green-500);
  --kog-stq-correct-icon-background-color: var(--kog-colors-green-500);
  --kog-stq-correct-icon-color: var(--kog-text-brand);
  --kog-stq-incorrect-background-color: var(--kog-colors-pink-100);
  --kog-stq-incorrect-border-color: var(--kog-colors-pink-500);
  --kog-stq-incorrect-icon-background-color: var(--kog-colors-pink-500);
  --kog-stq-incorrect-icon-color: var(--kog-text-brand);

  /* fitbq */
  --kog-fitbq-border-color: var(--kog-input-outline-border-color);
  --kog-fitbq-initial-background-color: var(--kog-colors-grey-100);
  --kog-fitbq-initial-focus-background-color: var(--kog-colors-white);
  --kog-fitbq-initial-focus-border-color: var(--kog-input-outline-focus-border-color);
  --kog-fitbq-initial-hover-border-color: var(--kog-colors-aubergine-700);
  --kog-fitbq-number-background-color: var(--kog-colors-grey-200);
  --kog-fitbq-focus-number-background-color: var(--kog-colors-aubergine-200);
  --kog-fitbq-corrected-number-background-color: var(--kog-colors-white);
  --kog-fitbq-correct-background-color: var(--kog-colors-green-300);
  --kog-fitbq-correct-border-color: var(--kog-colors-green-700);
  --kog-fitbq-correct-hover-border-color: var(--kog-colors-green-900);
  --kog-fitbq-incorrect-background-color: var(--kog-colors-pink-300);
  --kog-fitbq-incorrect-border-color: var(--kog-input-outline-error-border-color);
  --kog-fitbq-incorrect-hover-border-color: var(--kog-colors-pink-900);
  --kog-fitbq-disabled-background-color: var(--kog-colors-white);
  --kog-fitbq-disabled-border-color: var(--kog-colors-grey-600);

  /* progress circle text */
  --kog-progress-circle-primary-text-color: var(--kog-text-brand);
  --kog-progress-circle-positive-text-color: var(--kog-text-brand);
  --kog-progress-circle-mixed-text-color: var(--kog-text-brand);
  --kog-progress-circle-negative-text-color: var(--kog-text-brand);
  --kog-progress-circle-warning-text-color: var(--kog-text-brand);

  /* avatar */
  --kog-avatar-initials-primary-color: var(--kog-text-brand-hard);
  --kog-avatar-initials-primary-background-color: var(--kog-colors-aubergine-600);
  --kog-avatar-initials-accent-color: var(--kog-text-brand);
  --kog-avatar-initials-accent-background-color: var(--kog-border-accent-500);
  --kog-avatar-initials-dark-color: var(--kog-colors-white);
  --kog-avatar-initials-dark-background-color: color-mod(var(--kog-colors-grey-800) alpha(85%));
  --kog-avatar-initials-dark-hover-background-color: var(--kog-colors-grey-800);

  /* multiple choice question */
  --kog-mcq-background-color: var(--kog-colors-aubergine-100);
  --kog-mcq-gray-background-color: var(--kog-colors-grey-300);
  --kog-mcq-hover-outline-color: var(--kog-colors-aubergine-700);
  --kog-mcq-icon-background-color: var(--kog-colors-aubergine-700);
  --kog-mcq-icon-color: var(--kog-colors-white);
  --kog-mcq-outline-background-color: var(--kog-colors-white);
  --kog-mcq-outline-border-color: var(--kog-component-outline-border-color);
  --kog-mcq-selected-border-color: var(--kog-colors-aubergine-600);
  --kog-mcq-selected-icon-background-color: var(--kog-colors-aubergine-600);
  --kog-mcq-correct-correctness-icon-color: var(--kog-colors-green-800);
  --kog-mcq-correct-background-color: var(--kog-colors-green-100);
  --kog-mcq-correct-border-color: var(--kog-colors-green-800);
  --kog-mcq-correct-icon-background-color: var(--kog-colors-green-800);
  --kog-mcq-correct-icon-color: var(--kog-colors-white);
  --kog-mcq-incorrect-background-color: var(--kog-colors-pink-100);
  --kog-mcq-incorrect-border-color: var(--kog-colors-pink-700);
  --kog-mcq-incorrect-icon-background-color: var(--kog-colors-pink-800);
  --kog-mcq-incorrect-icon-color: var(--kog-colors-white);
  --kog-mcq-incorrect-correctness-icon-color: var(--kog-colors-pink-700);
  --kog-mcq-disabled-icon-color: var(--kog-colors-grey-800);
  --kog-mcq-disabled-icon-background-color: var(--kog-colors-white);

  /* tabs and tab */
  --kog-tabs-border-bottom-color: var(--kog-colors-grey-500);
  --kog-tab-background-color: var(--kog-colors-white);
  --kog-tab-border-bottom-color: var(--kogTransparent);
  --kog-tab-hover-background-color: var(--kog-list-item-hover-background);
  --kog-tab-transparent-color: var(--kogTransparent);
  --kog-tab-active-border-bottom-color: var(--kog-border-brand-900);
  --kog-tab-active-color: var(--kog-text-default);
  --kog-tab-inactive-color: var(--kog-text-default);

  /* card */
  --kog-card-background: var(--kog-background-default-0);
  --kog-card-icon-background-color: var(--kog-card-icon-background);
  --kog-card-icon-color: var(--kog-card-icon-color);
  --kog-selectable-card-selected-background-color: var(--kog-colors-aubergine-100);
  --kog-selectable-card-selected-border-color: var(--kog-colors-aubergine-700);

  /* table */
  --kog-table-title-background-color: var(--kog-colors-white);
  --kog-table-header-background-color: var(--kog-colors-grey-200);
  --kog-table-headerlike-background-color: var(--kog-colors-grey-100);
  --kog-table-row-background: var(--kog-colors-white);
  --kog-table-row-border-color: var(--kog-colors-grey-500);
  --kog-table-row-color: var(--kog-text-default);
  --kog-table-row-disabled-color: var(--kog-text-disabled);
  --kog-table-row-hover-background: var(--kog-list-item-hover-background);
  --kog-table-row-selected-background: var(--kog-list-item-selected-background);
  --kog-table-row-selected-hover-background: var(--kog-list-item-selected-hover-background);
  --kog-table-sticky-cell-background-color: var(--kog-colors-white);

  /* toaster */
  --kog-toaster-informative-border-color: var(--kog-colors-aubergine-900);
  --kog-toaster-positive-border-color: var(--kog-colors-green-500);
  --kog-toaster-negative-border-color: var(--kog-colors-pink-500);


  /* Not yet used vars beyond this point. Please move them up if you use them. */

  /* Page */
  --kog-page-background-0: var(--kog-colors-grey-0);
  --kog-page-background-50: var(--kog-colors-grey-50);
  --kog-page-background-100: var(--kog-colors-grey-100);
  --kog-page-background-400: var(--kog-colors-grey-400);

  /* Icons */
  --kog-icon-default: var(--kog-colors-grey-900);
  --kog-icon-muted: var(--kog-colors-grey-700);
  --kog-icon-disabled: var(--kog-colors-grey-500);
  --kog-icon-soft: var(--kog-colors-grey-900);
  --kog-icon-hard: var(--kog-colors-grey-0);
  --kog-icon-success: var(--kog-colors-green-900);
  --kog-icon-successmuted: var(--kog-colors-green-700);
  --kog-icon-danger: var(--kog-colors-pink-900);
  --kog-icon-dangermuted: var(--kog-colors-pink-700);

  /* Activities */
  --kog-activitytype-icon: var(--kog-colors-grey-900);
  --kog-activitytype-background-module: var(--kog-background-danger-200);
  --kog-activitytype-background-moduleexpanded: var(--kog-background-danger-500);
  --kog-activitytype-background-unit: var(--kog-background-default-400);
  --kog-activitytype-background-unitexpanded: var(--kog-background-brand-400);

  /* Modal(Dialog) */
  --kog-modal-0-background: var(--kog-background-default-0);
  --kog-modal-0-border: var(--kog-border-default-200);
  --kog-modal-0-text: var(--kog-text-default);
  --kog-modal-0-icon: var(--kog-icon-default);
  --kog-modal-200-background: var(--kog-background-default-200);
  --kog-modal-200-border: var(--kog-border-default-400);
  --kog-modal-200-text: var(--kog-text-default);
  --kog-modal-200-icon: var(--kog-icon-default);
  --kog-modal-400-background: var(--kog-background-default-400);
  --kog-modal-400-border: var(--kog-border-default-600);
  --kog-modal-400-text: var(--kog-text-default);
  --kog-modal-400-icon: var(--kog-icon-default);
  --kog-modal-700-fill: var(--kog-background-default-700);
  --kog-modal-700-border: var(--kog-border-default-900);
  --kog-modal-700-text: var(--kog-text-hard);
  --kog-modal-700-icon: var(--kog-icon-hard);
}
