.content-editable .inline-question {
  position: relative;

  clear: both;

  margin-right: calc(var(--space-l) * -1);
  margin-bottom: var(--space-xl);
  margin-left: calc(var(--space-l) * -1);
  padding: var(--space-l) var(--space-xxl) var(--space-xl) 80px;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: 22px;

  background: var(--kog-content-red-light-5);
  border-radius: 24px;
}

.content-editable [contenteditable='true'] .inline-question {
  margin-right: 0;
  margin-left: 0;
}

.content-editable .inline-question h5 {
  margin-top: 0 !important;
  margin-bottom: var(--space-xs);

  font-family: var(--kog-satoshi);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}

.content-editable .inline-question .inline-question-content p,
.content-editable .inline-question .inline-question-content ol li,
.content-editable .inline-question .inline-question-content ul li {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  line-height: 28px;
  color: var(--kogPlatformGray018);
}

.content-editable .inline-question .inline-question-content p,
.content-editable .inline-question .inline-question-content ol,
.content-editable .inline-question .inline-question-content ul {
  margin-bottom: 1em;
}

.content-editable .inline-question img {
  float: none !important;
}

.content-editable .inline-question .inline-question-view-answer {
  cursor: pointer;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  color: var(--kogPlatformGray018);
}

.content-editable .inline-question .inline-question-answer {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  color: var(--kogPlatformGray018);
}

.showsolution {
  display: flex;
  flex-direction: column;
}

.showsolution-toggle-link {
  align-self: flex-start;
}

.ck-widget .showsolution-toggle-link {
  margin-bottom: var(--space-s);
  padding: var(--space-xs) var(--space-l);

  line-height: var(--space-xl);

  border: 1px solid var(--kog-button-default-fill-border-default);
  border-radius: 4px;
}

.ContentBox-content .ck-widget .showsolution-toggle-link,
.anybox:not(.hlextensionbox):not(.extendedcontentbox) .ck-widget .showsolution-toggle-link {
  background-color: var(--kogPlatformWhite);
}

.showsolution .js-showsolution-toggle[aria-expanded='true'] {
  margin-bottom: var(--space-s);
}

.showsolution-toggle-link a {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  color: var(--kog-button-default-fill-label-default);
  text-decoration: none;

  &:hover, &:focus {
    color: var(--kog-button-default-fill-label-default);
    text-decoration: none;
  }
}

.showsolution-content {
  overflow-x: auto;
  padding: var(--space-l);
  background-color: var(--kogPlatformGray096);
  border-radius: 16px;
}

td .showsolution-content,
.anybox .showsolution-content {
  margin: 0;
}

.anybox:not(.hlextensionbox):not(.extendedcontentbox) .showsolution-content {
  background-color: var(--kogPlatformWhite);
}

.hidden-widget.showsolution-content {
  display: none;

  @media print {
    display: block;
    height: auto;
  }
}
