.content-editable {
  & .collapsible {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  & .collapsible-toggle {
    display: inline-flex;
    align-self: start;
    text-decoration: none;

    &[aria-expanded="true"] {
      margin-bottom: var(--space-s);
    }
    &.ck-placeholder {
      min-width: 150px;
    }
  }

  & .collapsible-longdescription {
    font-family: var(--kog-satoshi);
    font-size: var(--kog-font-size-default);

    & .collapsible-toggle {
      align-self: end;
      color: var(--kog-text-muted);
    }

    & .collapsible-content[aria-hidden="false"] {
      padding: var(--space-xs);
      background-color: var(--kog-background-default-50);
      border: 1px solid var(--kog-border-default-200);
      border-radius: var(--space-xs);
    }
  }
}
