/*
Inspired from Bootstrap 5.0.0 https://v5.getbootstrap.com/docs/5.0/layout/grid/
Major differences include:
1. No xxl size
2. Classes prefixed with kog-, specifically to not cause issues
with existing Bootstrap 3
3. Default gutter-x spacing is 24px instead of 30px
4. Does not include customisable/responsive gutters
*/

.kog-container,
.kog-container-fluid,
.kog-container-sm,
.kog-container-md,
.kog-container-lg,
.kog-container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--space-s);
  padding-left: var(--space-s);
}

@media (min-width: 576px) {
  .kog-container,
  .kog-container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .kog-container,
  .kog-container-sm,
  .kog-container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .kog-container,
  .kog-container-sm,
  .kog-container-md,
  .kog-container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .kog-container,
  .kog-container-sm,
  .kog-container-md,
  .kog-container-lg,
  .kog-container-xl {
    max-width: 1140px;
  }
}

.kog-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--space-s) * -1);
  margin-left: calc(var(--space-s) * -1);
}

.kog-row > * {
  flex-shrink: 0;

  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding-right: var(--space-s);
  padding-left: var(--space-s);
}

.kog-col {
  flex: 1 0 0;
}

.kog-row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.kog-row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.kog-row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.kog-row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.kog-row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.kog-row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.kog-row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

.kog-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.kog-col-1 {
  flex: 0 0 auto;
  width: 8.333333%;
}

.kog-col-2 {
  flex: 0 0 auto;
  width: 16.666667%;
}

.kog-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.kog-col-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.kog-col-5 {
  flex: 0 0 auto;
  width: 41.666667%;
}

.kog-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.kog-col-7 {
  flex: 0 0 auto;
  width: 58.333333%;
}

.kog-col-8 {
  flex: 0 0 auto;
  width: 66.666667%;
}

.kog-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.kog-col-10 {
  flex: 0 0 auto;
  width: 83.333333%;
}

.kog-col-11 {
  flex: 0 0 auto;
  width: 91.666667%;
}

.kog-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.kog-offset-1 {
  margin-left: 8.333333%;
}

.kog-offset-2 {
  margin-left: 16.666667%;
}

.kog-offset-3 {
  margin-left: 25%;
}

.kog-offset-4 {
  margin-left: 33.333333%;
}

.kog-offset-5 {
  margin-left: 41.666667%;
}

.kog-offset-6 {
  margin-left: 50%;
}

.kog-offset-7 {
  margin-left: 58.333333%;
}

.kog-offset-8 {
  margin-left: 66.666667%;
}

.kog-offset-9 {
  margin-left: 75%;
}

.kog-offset-10 {
  margin-left: 83.333333%;
}

.kog-offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .kog-col-sm {
    flex: 1 0 0;
  }

  .kog-row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .kog-row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-col-sm-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }

  .kog-col-sm-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-col-sm-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-col-sm-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }

  .kog-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-col-sm-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }

  .kog-col-sm-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }

  .kog-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .kog-col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }

  .kog-col-sm-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }

  .kog-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-offset-sm-0 {
    margin-left: 0;
  }

  .kog-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .kog-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .kog-offset-sm-3 {
    margin-left: 25%;
  }

  .kog-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .kog-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .kog-offset-sm-6 {
    margin-left: 50%;
  }

  .kog-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .kog-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .kog-offset-sm-9 {
    margin-left: 75%;
  }

  .kog-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .kog-offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .kog-col-md {
    flex: 1 0 0;
  }

  .kog-row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .kog-row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-col-md-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }

  .kog-col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-col-md-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }

  .kog-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }

  .kog-col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }

  .kog-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .kog-col-md-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }

  .kog-col-md-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }

  .kog-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-offset-md-0 {
    margin-left: 0;
  }

  .kog-offset-md-1 {
    margin-left: 8.333333%;
  }

  .kog-offset-md-2 {
    margin-left: 16.666667%;
  }

  .kog-offset-md-3 {
    margin-left: 25%;
  }

  .kog-offset-md-4 {
    margin-left: 33.333333%;
  }

  .kog-offset-md-5 {
    margin-left: 41.666667%;
  }

  .kog-offset-md-6 {
    margin-left: 50%;
  }

  .kog-offset-md-7 {
    margin-left: 58.333333%;
  }

  .kog-offset-md-8 {
    margin-left: 66.666667%;
  }

  .kog-offset-md-9 {
    margin-left: 75%;
  }

  .kog-offset-md-10 {
    margin-left: 83.333333%;
  }

  .kog-offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .kog-col-lg {
    flex: 1 0 0;
  }

  .kog-row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .kog-row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }

  .kog-col-lg-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-col-lg-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }

  .kog-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-col-lg-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }

  .kog-col-lg-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }

  .kog-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .kog-col-lg-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }

  .kog-col-lg-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }

  .kog-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-offset-lg-0 {
    margin-left: 0;
  }

  .kog-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .kog-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .kog-offset-lg-3 {
    margin-left: 25%;
  }

  .kog-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .kog-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .kog-offset-lg-6 {
    margin-left: 50%;
  }

  .kog-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .kog-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .kog-offset-lg-9 {
    margin-left: 75%;
  }

  .kog-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .kog-offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .kog-col-xl {
    flex: 1 0 0;
  }

  .kog-row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .kog-row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .kog-col-xl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }

  .kog-col-xl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .kog-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .kog-col-xl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .kog-col-xl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }

  .kog-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .kog-col-xl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }

  .kog-col-xl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }

  .kog-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .kog-col-xl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }

  .kog-col-xl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }

  .kog-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .kog-offset-xl-0 {
    margin-left: 0;
  }

  .kog-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .kog-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .kog-offset-xl-3 {
    margin-left: 25%;
  }

  .kog-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .kog-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .kog-offset-xl-6 {
    margin-left: 50%;
  }

  .kog-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .kog-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .kog-offset-xl-9 {
    margin-left: 75%;
  }

  .kog-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .kog-offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (max-width: 767px) {
  .kog-hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .kog-hidden-sm {
    display: none !important;
  }
}
