.content-editable .CKEditorKognityContentGrid {
  width: 100%;
  min-height: 10px;
}

.CKEditorKognityContentGrid-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto;
  align-items: start;
  justify-content: center;
}

.content-editable [contenteditable='true'] .CKEditorKognityContentGrid,
.content-editable [contenteditable='true'] .CKEditorKognityContentGrid-grid {
  padding: 15px;
  border: 1px solid #000;
}

.CKEditorKognityContentGrid-grid img {
  max-width: 100%;
  height: auto;
}

.CKEditorKognityContentGrid-grid[data-columns='2'] {
  grid-template-columns: repeat(2, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='3'] {
  grid-template-columns: repeat(3, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='4'] {
  grid-template-columns: repeat(4, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='5'] {
  grid-template-columns: repeat(5, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='6'] {
  grid-template-columns: repeat(6, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='7'] {
  grid-template-columns: repeat(7, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='8'] {
  grid-template-columns: repeat(8, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='9'] {
  grid-template-columns: repeat(9, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='10'] {
  grid-template-columns: repeat(10, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='11'] {
  grid-template-columns: repeat(11, 1fr);
}

.CKEditorKognityContentGrid-grid[data-columns='12'] {
  grid-template-columns: repeat(12, 1fr);
}
