.content-editable table p {
  margin-bottom: 1em;
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
  line-height: 22px;
}

.content-editable table {
  margin-bottom: 32px !important;
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 500;
}

.content-editable table.table-bordered {
  border: 1px solid color-mod(var(--kog-content-gray-dark-1) alpha(20%));
}

.content-editable table.table-content-list > thead > tr > th,
.content-editable table.table-content-list > tbody > tr > th {
  background-color: color-mod(var(--kog-content-gray-dark-1) alpha(10%));
}

.content-editable table.table-content-list > thead > tr > th,
.content-editable table.table-content-list > tbody > tr > th,
.content-editable table.table-content-list > tbody > tr > td {
  padding: var(--space-m);
  border: 1px solid color-mod(var(--kog-content-gray-dark-1) alpha(20%));
}

.content-editable table.table-content-list > tbody > tr > th {
  font-weight: bold;
}

.content-editable table.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: color-mod(var(--kog-content-gray-dark-1) alpha(5%));
}

.ck.ck-dropdown__panel {
  overflow-y: scroll;
  max-height: 60vh;
}

.content-editable .table table {
  background-color: var(--kogTransparent);
}
