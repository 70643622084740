/* stylelint-disable plugin/selector-bem-pattern */

.KogButtonSet {
  display: flex;
  flex-wrap: unset;
  align-items: center;
  justify-content: flex-start;
}

.KogButtonSet--right {
  justify-content: flex-end;
}

.KogButtonSet--center {
  justify-content: center;
}

.KogButtonSet--sides {
  justify-content: space-between;
}

.KogButtonSet .KogButtonLegacy,
.KogButtonSet .KogButton {
  margin-right: 10px;
  margin-left: 0;
}

.KogButtonSet--right .KogButtonLegacy,
.KogButtonSet--right .KogButton {
  margin-right: 0;
  margin-left: 10px;
}

.KogButtonSet--center .KogButtonLegacy,
.KogButtonSet--center .KogButton {
  margin-right: 5px;
  margin-left: 5px;
}

.KogButtonSet--sides .KogButtonLegacy,
.KogButtonSet--sides .KogButton {
  margin-right: 0;
  margin-left: 0;
}
